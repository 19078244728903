import { useMutation } from '@tanstack/react-query';
import { login } from '../../api';
import { useAuth } from '../useAuth';
import { toastEmitter } from 'components/Toast';

export default function useLogin() {
  const { setUserToken } = useAuth();
  const loginRequest = useMutation({
    mutationFn: login,
    mutationKey: 'login',
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }
      console.log(responseData);
      setUserToken(responseData?.data?.token);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      toastEmitter({ type: 'secondary', message: errMsg });
    },
  });
  return { ...loginRequest };
}
