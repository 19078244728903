import React from 'react';

// import { useAuth } from 'hooks/useAuth';
import Personal from './Personal';

// const SettingsArray = [
//   {
//     id: 1,
//     label: 'Personal',
//     value: 'personal',
//     access: true,
//   },
//   {
//     id: 2,
//     label: 'Business',
//     value: 'business',
//     access: true,
//   },
// ];

const Profile = () => {
  // const [tab, setTab] = useState('personal');
  // const { user } = useAuth();
  return (
    <div className="overflow-auto w-full px-6 pt-6 pb-6">
      <div className="flex items-center justify-between mb-5">
        <div className="text-sm text-grey-700">Manage your Fonu account</div>
      </div>
      <div className="table w-full rounded-md border border-grey-400 bg-white ">
        <div className="flex items-center w-full border-b border-grey-300 gap-[34px] px-6 ">
          {/* {SettingsArray.map((setting) => {
            if (setting?.access?.length === 0) {
              return;
            }
            return (
              <div
                key={setting?.id}
                onClick={() => setTab(setting?.value)}
                className="flex justify-center items-center group relative"
              >
                <div
                  className={`${
                    tab === setting?.value
                      ? 'text-grey-900 font-semibold'
                      : 'text-grey-600'
                  } text-base  cursor-pointer mb-2`}
                >
                  {setting?.label}
                </div>
                {tab === setting?.value && (
                  <div className="group-hover:visible w-6 h-0.5 bg-grey-900  absolute top-[31px] visible rounded-full"></div>
                )}
              </div>
            );
          })} */}
          {/* <div className="text-grey-900 font-semibold mt:3"></div> */}
          {/* <div className="group-hover:visible w-6 h-0.5 bg-grey-900  absolute top-[31px] visible rounded-full "></div> */}
        </div>

        <Personal />
      </div>
    </div>
  );
};

export default Profile;
