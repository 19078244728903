import React, { useState } from 'react';
import { Button, Dropdown, Spinner } from 'components';
import { Chevron, Refresh } from 'assets/images';
import { useChart } from 'hooks';
import useDashboardStats from 'hooks/useDashboardStats';

export default function Dashboard() {
  const { getChart } = useChart();
  const [statsType, setStatsType] = useState('weekly');
  const { isLoading, data } = useDashboardStats({ statsType });
  const graphData = data?.data?.data?.graph ?? [];

  return (
    <div className="bg-grey-100 body-height overflow-auto">
      <div className="p-3">
        <div>
          <div className="">
            <div className="w-full">
              <div className="flex items-center gap-6 mb-2">
                <div className=" text-xl font-medium	 text-grey-900">
                  Call summary
                </div>
                <div className="w-32">
                  <Button
                    rounded={true}
                    height="h-10"
                    extraClasses=" px-5"
                    background="grey-200 "
                    border="none"
                  >
                    <div className="text-black flex items-center gap-2">
                      Refresh
                      <Refresh />
                    </div>
                  </Button>
                </div>
              </div>

              <div className="border-grey-400 border  rounded-lg  py-4 px-5 bg-white min-h-400  ">
                <div className="flex items-center justify-between xxl:mb-8 xl:mb-8	lg:mb-8 md:mb-2 sm:mb-2 xs:mb-2  gap-4">
                  <div className="flex items-center gap-5 ">
                    <div className="flex items-center gap-2">
                      <div className=" w-3 h-3 rounded-full bg-green-300"></div>
                      Incoming
                    </div>
                    <div className="flex items-center gap-2">
                      <div className=" w-3 h-3 rounded-full bg-green"></div>
                      Outgoing
                    </div>
                    <div className="flex items-center gap-2">
                      <div className=" w-3 h-3 rounded-full bg-danger"></div>
                      Missed
                    </div>
                  </div>

                  <div>
                    <Dropdown
                      dropList={{
                        component: ({ item }) => (
                          <span
                            key={item?.id}
                            className="w--full d--flex  justify-content--start py-2 px-3"
                            onClick={() => setStatsType(item?.value)}
                          >
                            {item?.title}
                          </span>
                        ),
                        data: [
                          { title: 'Weekly', value: 'weekly', id: 1 },
                          { title: 'Monthly', value: 'monthly', id: 2 },
                        ],
                      }}
                      closeOnClickOutside={true}
                    >
                      <div className="flex text-sm	 items-center gap-5 font-medium	 bg-grey-300 cursor-pointer text-grey-900 px-4 py-2 rounded-full  capitalize">
                        {statsType}
                        <Chevron />
                      </div>
                    </Dropdown>
                  </div>
                </div>

                {isLoading ? (
                  <Spinner />
                ) : statsType === 'weekly' ? (
                  getChart('ColumnChart', [
                    ['Day', 'Outgoing', 'Incoming', 'Missed'],
                    [
                      'Monday',
                      graphData?.[0]?.['Outbound'] ?? 0,
                      graphData?.[0]?.['Inbound'] ?? 0,
                      graphData?.[0]?.['Missed'] ?? 0,
                    ],
                    [
                      'Tuesday',
                      graphData?.[1]?.['Outbound'] ?? 0,
                      graphData?.[1]?.['Inbound'] ?? 0,
                      graphData?.[1]?.['Missed'] ?? 0,
                    ],
                    [
                      'Wednesday',
                      graphData?.[2]?.['Outbound'] ?? 0,
                      graphData?.[2]?.['Inbound'] ?? 0,
                      graphData?.[2]?.['Missed'] ?? 0,
                    ],
                    [
                      'Thursday',
                      graphData?.[3]?.['Outbound'] ?? 0,
                      graphData?.[3]?.['Inbound'] ?? 0,
                      graphData?.[3]?.['Missed'] ?? 0,
                    ],
                    [
                      'Friday',
                      graphData?.[4]?.['Outbound'] ?? 0,
                      graphData?.[4]?.['Inbound'] ?? 0,
                      graphData?.[4]?.['Missed'] ?? 0,
                    ],
                    [
                      'Saturday',
                      graphData?.[5]?.['Outbound'] ?? 0,
                      graphData?.[5]?.['Inbound'] ?? 0,
                      graphData?.[5]?.['Missed'] ?? 0,
                    ],
                    [
                      'Sunday',
                      graphData?.[6]?.['Outbound'] ?? 0,
                      graphData?.[6]?.['Inbound'] ?? 0,
                      graphData?.[6]?.['Missed'] ?? 0,
                    ],
                  ])
                ) : (
                  getChart('ColumnChart', [
                    ['Day', 'Outgoing', 'Incoming', 'Missed'],
                    [
                      'Jan',
                      graphData?.[0]?.['Outbound'] ?? 0,
                      graphData?.[0]?.['Inbound'] ?? 0,
                      graphData?.[0]?.['Missed'] ?? 0,
                    ],
                    [
                      'Feb',
                      graphData?.[1]?.['Outbound'] ?? 0,
                      graphData?.[1]?.['Inbound'] ?? 0,
                      graphData?.[1]?.['Missed'] ?? 0,
                    ],
                    [
                      'Mar',
                      graphData?.[2]?.['Outbound'] ?? 0,
                      graphData?.[2]?.['Inbound'] ?? 0,
                      graphData?.[2]?.['Missed'] ?? 0,
                    ],
                    [
                      'Apr',
                      graphData?.[3]?.['Outbound'] ?? 0,
                      graphData?.[3]?.['Inbound'] ?? 0,
                      graphData?.[3]?.['Missed'] ?? 0,
                    ],
                    [
                      'May',
                      graphData?.[4]?.['Outbound'] ?? 0,
                      graphData?.[4]?.['Inbound'] ?? 0,
                      graphData?.[4]?.['Missed'] ?? 0,
                    ],
                    [
                      'Jun',
                      graphData?.[5]?.['Outbound'] ?? 0,
                      graphData?.[5]?.['Inbound'] ?? 0,
                      graphData?.[5]?.['Missed'] ?? 0,
                    ],
                    [
                      'Jul',
                      graphData?.[6]?.['Outbound'] ?? 0,
                      graphData?.[6]?.['Inbound'] ?? 0,
                      graphData?.[6]?.['Missed'] ?? 0,
                    ],
                    [
                      'Aug',
                      graphData?.[7]?.['Outbound'] ?? 0,
                      graphData?.[7]?.['Inbound'] ?? 0,
                      graphData?.[7]?.['Missed'] ?? 0,
                    ],
                    [
                      'Sep',
                      graphData?.[8]?.['Outbound'] ?? 0,
                      graphData?.[8]?.['Inbound'] ?? 0,
                      graphData?.[8]?.['Missed'] ?? 0,
                    ],
                    [
                      'Oct',
                      graphData?.[9]?.['Outbound'] ?? 0,
                      graphData?.[9]?.['Inbound'] ?? 0,
                      graphData?.[9]?.['Missed'] ?? 0,
                    ],
                    [
                      'Nov',
                      graphData?.[10]?.['Outbound'] ?? 0,
                      graphData?.[10]?.['Inbound'] ?? 0,
                      graphData?.[10]?.['Missed'] ?? 0,
                    ],
                    [
                      'Dec',
                      graphData?.[11]?.['Outbound'] ?? 0,
                      graphData?.[11]?.['Inbound'] ?? 0,
                      graphData?.[11]?.['Missed'] ?? 0,
                    ],
                  ])
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
