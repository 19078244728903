export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};

export const dropdownActions = {
  ADD_COMPANY: 'add_company',
  View: 'view',
  // UPDATE_COMPANY: 'update_company',
  ACTIVATE_COMPANY: 'activate_company',
  DEACTIVATE_COMPANY: 'deactivate_company',
};
