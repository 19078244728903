import { Edit, Password } from 'assets/images';
import React from 'react';
import { NavLink } from 'react-router-dom';

const NavigationArr = [
  {
    id: 1,
    parent: 'profile',
    path: '/profile/general',
    label: 'Profile',
    icon: Edit,
    access: true,
  },
  {
    id: 2,
    parent: 'profile',
    path: '/profile/changepassword',
    label: 'Change password',
    icon: Password,
    access: true,
  },
];

const Sidebar = () => {
  return (
    <div className=" xxl:min-w-[290px] xl:min-w-[250px] lg:min-w-[256px] md:min-w-[230px] pt-7 flex flex-col border-grey-300 border-r">
      <div className="flex flex-col gap-2.5 px-4 mb-2">
        {NavigationArr?.map((nav) => (
          <NavLink
            key={nav?.id}
            to={nav?.path}
            className={({ isActive }) =>
              `xxl:text-base xl:text-base lg:text-sm md:text-sm  flex items-center gap-3 p-3 rounded-md hover:bg-grey-300 cursor-pointer  ${
                isActive ? 'bg-grey-300' : ''
              }`
            }
          >
            <nav.icon className="w-6 h-6" />
            {nav?.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
