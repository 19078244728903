import React, { useState } from 'react';
import { Button, TableManager } from 'components';
import { getTransactionListing } from 'api';
import { formatDateTime } from 'shared/resources';

function Transaction() {
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: (props) => {
        const [date] = formatDateTime(props?.getValue());
        return <span>{date}</span>;
      },
    },
    {
      accessorKey: 'bill_no',
      header: () => '	Transaction No',
    },

    {
      accessorKey: 'type',
      header: () => '	Type',
    },
    {
      accessorKey: 'mode',
      header: () => 'Mode',
    },
    {
      accessorKey: 'desc',
      header: () => 'Description',
      cell: (props) => (
        <span
          style={{
            textTransform: 'capitalize',
          }}
        >
          {props?.getValue()?.toLowerCase() ?? ''}
        </span>
      ),
    },

    {
      accessorKey: 'total_amount',
      header: () => 'Amount',

      meta: {
        isSortable: true,
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
    },
  ];

  const tableActions = [
    {
      id: 1,
      label: 'Get a Fonu number +',

      component: (
        <div className=" w-64">
          <Button type="button" height="h-10">
            Get a Fonu number +
          </Button>
        </div>
      ),
    },
  ];

  const filterInitialValues = {
    filter: [
      {
        account: '',
        a_filter_type: '',
        placeholder: 'Enter account',
        type: 'text',
        shouldFilterOptionDisabled: false,
        label: 'Account',
        filterName: 'account',
        position: 1,
      },
    ],
  };
  return (
    <>
      <div className="flex items-center justify-between mb-5"></div>

      <TableManager
        fetcherFn={getTransactionListing}
        fetcherKey={'getTransactionListing'}
        searchPlaceholder={'Search by number, name, or members'}
        searchLabel=""
        isSearchable={true}
        shouldFilter={true}
        columns={columns}
        tableActions={tableActions}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
      />
    </>
  );
}

export default Transaction;
