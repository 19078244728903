import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

function AutoTypeComponent({ extraClass = '' }) {
  const el = useRef(null);

  useEffect(() => {
    if (el?.current) {
      const typed = new Typed(el.current, {
        strings: ['Communication', 'Engagement', 'Connectivity', 'Activation'],
        typeSpeed: 90,
        backSpeed: 50,
        loop: true,
      });

      return () => {
        typed.destroy();
      };
    }
  }, [el?.current]);

  return (
    <div className="flex flex-col text-center">
      <div className="xxl:text-4xl xl:text-3xl lg:text-[24px] md:text-[22px] text-white font-medium">
        Modern <span ref={el} className={extraClass} />
        <br></br> Platform for local businesses in Africa.
      </div>
      <div
        className={`xxl:text-base xl:text-[15px] lg:text-[14px] md:text-[12px]   mt-3 ${extraClass}`}
      >
        Fonu is the easiest way for businesses to access telco services.
      </div>
    </div>
  );
}

export default AutoTypeComponent;
