import React from 'react';

export default function Button({
  type = 'button',
  children,
  background = 'green',
  border = 'green',
  extraClasses = '',
  rounded = false,
  color = 'text-white',
  height = 'xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]',
  width = 'w-full',
  ...rest
}) {
  return (
    <button
      {...rest}
      type={type}
      className={`flex justify-center items-center bg-${background} ${height} ${color}  xxl:text-base xl:text-sm lg:text-sm md:text-xs sm:text-xs xs:text-xs ${width} ${
        rounded ? 'rounded-full' : 'rounded-md'
      } focus:ring focus:ring-green-200 focus:outline-none   disabled:bg-green-100 disabled:text-green-200 disabled:border-none disabled:cursor-no-drop   border ${border} ${extraClasses}`}
    >
      {children}
    </button>
  );
}
