import { Button, Input, Spinner } from 'components';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { getRequiredText } from 'shared/resources';
import { useLogin } from 'hooks';
import { LogoFullWhite, Vectordown, Vectorup } from 'assets/images';
import AutoTypeComponent from 'components/AutoTypeComponent';

const formHeading = {
  0: 'Fonu Admin Login',
};

const formSubHeading = {
  0: 'Login with Phone Number',
};

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(getRequiredText('Email'))
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter valid email address',
    ),
  password: yup.string().required(getRequiredText('Password')),
});

export default function Login() {
  const { mutate: loginMutate, isPending: isLoginPending } = useLogin();
  
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const onSubmit = (values) => {
    loginMutate(values);
  };
  return (
    <div className="flex h-screen">
      <div className="bg-green-50 w-screen overflow-auto relative xxl:pt-10 xl:pt-10 lg:pt-8 md:pt-5 sm:pt-8 xs:pt-5">
        <div className="m-auto xxl:w-6/12 xl:w-6/12 lg:w-6/12 md:w-6/12 sm:w-8/12 xs:w-11/12">
          <div className="flex flex-col">
            <div className="xxl:text-3xl xl:text-[28px] lg:text-[24px] md:text-[22px] text-black font-medium lg:w-6/">
              {formHeading[0]}
            </div>
            <div className="xxl:text-base xl:text-[15px] lg:text-[15px] md:text-[14px] font-normal text-grey-600 xxl:mt-[.1em]">
              {formSubHeading[0]}
            </div>
          </div>
          <form
            className="w--full d--flex flex--column gap--md"
            onSubmit={handleSubmit(onSubmit)}
            // autoComplete="off"
          >
            <div className="flex flex-col">
              <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      required={true}
                      label="Email"
                      error={errors?.email?.message}
                    />
                  )}
                />
              </div>
              <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="password"
                      required={true}
                      label="Password"
                      maxLength="10"
                      autoComplete="new-password"
                      error={errors?.password?.message}
                    />
                  )}
                />
              </div>

              <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
                <Button type="submit" disabled={isLoginPending || isSubmitting}>
                  {isLoginPending ? <Spinner /> : 'Sign in'}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="h-full px-12 py-10 xxl:min-w-[570px] xl:min-w-[450px] lg:min-w-[380px] md:min-w-[365px] xxl:flex xl:flex lg:flex md:flex sm:hidden xs:hidden relative flex-col justify-between  	 bg-green">
        <div className="flex gap-3">
          <LogoFullWhite className=" xxl:w-28 xl:w-24 lg:w-[85px] md:w-20" />
        </div>
        <AutoTypeComponent extraClass={'text-black'} />
        <div className="absolute right-0 top-0 xxl:w-auto lg:w-5/6 md:w-4/6">
          <Vectorup className="w-full" />
        </div>
        <div className="absolute left-0 bottom-0 xxl:w-auto lg:w-5/6 md:w-4/6">
          <Vectordown className="w-full" />
        </div>
      </div>
    </div>
  );
}
