import React from 'react';
import { TableManager } from 'components';
import { getKycLogsList } from 'api';
import { colorLookup, formatDateTime } from 'shared/resources';
import { useParams } from 'react-router-dom';

function Reports() {
  const { id: company_uuid } = useParams();

  const columns = [
    {
      accessorKey: 'uuid',
      header: () => 'Verification reference',
      cell: (props) => <div>{props?.getValue()?.slice(0, 16)}</div>,
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: (props) => {
        const [date] = formatDateTime(props.getValue());
        return date;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'kyc_details.first_name',
      header: () => 'First name',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'kyc_details.last_name',
      header: () => 'Last name',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'kyc_doc_type',
      header: () => 'Document type',
      cell: (props) => (
        <span className="capitalize">
          {props?.getValue()?.toLowerCase()?.replaceAll('_', ' ')}
        </span>
      ),
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'rejection_reason',
      header: () => 'Rejection reason',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        return (
          <div
            className={`capitalize ${
              colorLookup[props?.getValue()?.toLowerCase()]
            }`}
          >
            {props?.getValue()?.toLowerCase()?.split('_')?.join(' ')}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between mb-5"></div>

      <TableManager
        fetcherFn={getKycLogsList}
        fetcherKey={'getKycLogsList'}
        // searchPlaceholder={'Search'}
        // searchLabel=""
        // isSearchable={true}
        // shouldFilter={true}
        title="Report Logs"
        extraParams={{ company_uuid }}
        columns={columns}
      />
    </>
  );
}

export default Reports;
