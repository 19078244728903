import React, { useState } from 'react';
import { darkenColor, lightenColor, stringToColour } from 'shared/resources';

const classes = {
  default: 'rounded-full overflow-hidden cursor-pointer',
  icon: 'rounded-md bg-white border  border-grey-400 p-1',
};
const Avatar = ({
  name = '',
  size = '40',
  mediaUrl = '',
  inGroup = false,
  type = 'default',
}) => {
  const [isBrokenImage, setIsBrokenImage] = useState(false);

  const NAME = name || 'Unknown';
  const nameColour = stringToColour(NAME);
  const lightColor = lightenColor(nameColour, 90);
  const darkColor = darkenColor(nameColour, 80);
  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${size / 2}px`,
      }}
      className={`${classes[type]} ${inGroup ? 'border-white border' : ''}`}
    >
      {mediaUrl ? (
        !isBrokenImage ? (
          <img
            loading="lazy"
            src={mediaUrl}
            onError={() => setIsBrokenImage(true)}
            className="w-full h-full object-contain"
            alt={`${NAME}-alt-avtar`}
          />
        ) : (
          <div
            style={{
              color: lightColor,
              background: darkColor,
              gap: '1.5px',
            }}
            className={`flex items-center rounded-full justify-center text-sm font-bold h-full w-full shadow-md`}
          >
            <span>{NAME?.split(' ')?.[0]?.charAt(0)?.toUpperCase()}</span>
            <span>{NAME?.split(' ')?.[1]?.charAt(0)?.toUpperCase()}</span>
          </div>
        )
      ) : (
        <div
          style={{
            color: lightColor,
            background: darkColor,
            gap: '1.5px',
          }}
          className={`flex items-center rounded-full justify-center text-sm font-bold h-full w-full shadow-md`}
        >
          <span>{NAME?.split(' ')?.[0]?.charAt(0)?.toUpperCase()}</span>
          <span>{NAME?.split(' ')?.[1]?.charAt(0)?.toUpperCase()}</span>
        </div>
      )}
    </div>
  );
};

export default React.memo(Avatar);
