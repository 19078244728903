import { X } from 'assets/images';
import Dropdown from 'components/Dropdown';
import { baseInputLabelClasses } from 'components/Input';
import React from 'react';
import { formatPhoneNumber } from 'shared/resources';

const CustomSelect = (
  {
    isMulti = false,
    ItemComponent = () => null,
    optionsList = [],
    label = '',
    placeholder = '',
    error = '',
    labelKey = '',
    valueKey = '',
    required = false,
    shouldFormat = false,
    maxHeight = true,
    isLoading = false,
    ...rest
  },
  ref,
) => {
  const handleCheckBoxChange = (value) => {
    if (rest?.value?.includes(value)) {
      const updatedValues = rest?.value?.filter((val) => val !== value);
      rest?.onChange(updatedValues);
    } else {
      rest?.onChange([...rest.value, value]);
    }
  };

  const handleRadioChange = (value) => {
    rest?.onChange(value);
  };

  const MultiDropItem = ({ fieldLabel, fieldValue, item = {} }) => {
    return (
      <label
        className="w-full flex cursor-pointer items-center justify-between"
        htmlFor={fieldLabel}
        onClick={() => handleCheckBoxChange(fieldValue)}
      >
        <ItemComponent key={item?.id} item={item} />
        <input
          className="checked:bg-green-300 active:bg-green-300 default:bg-green-300 enabled:hover:bg-green-100"
          id={fieldLabel}
          type="checkbox"
          checked={rest?.value?.includes(fieldValue)}
        />
      </label>
    );
  };

  const SingleDropItem = ({ fieldLabel, fieldValue, item = {} }) => {
    return (
      <label
        className="w-full flex cursor-pointer items-center justify-start"
        htmlFor={fieldLabel}
        onClick={() => handleRadioChange(fieldValue)}
      >
        <ItemComponent item={item} />
      </label>
    );
  };

  const ListItemToBeRendered = isMulti ? MultiDropItem : SingleDropItem;

  const dropListNotification = {
    component: ({ item }) => {
      const fieldValue = item?.[valueKey];
      const fieldLabel = item?.[labelKey];
      return (
        <ListItemToBeRendered
          fieldValue={fieldValue}
          fieldLabel={fieldLabel}
          item={item}
        />
      );
    },
    data: optionsList,
  };

  const handleRemoveItem = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    handleCheckBoxChange(value);
  };

  const normalizeValues = (value) => {
    const originalValue = value?.[valueKey] ? value?.[valueKey] : value;

    const option = optionsList?.find((option) => {
      return option[valueKey] === originalValue;
    });
    return (
      <div
        className="flex items-center justify-center  gap-1.5 rounded-full bg-green-100 text-green px-3 py-1 h-6 text-sm"
        key={option?.id}
      >
        {option?.[labelKey]}
        <span
          className="mt-1 cursor-pointer"
          onClick={(e) => handleRemoveItem(e, value)}
        >
          <X className="text-green" />
        </span>
      </div>
    );
  };

  const values = isMulti ? (
    rest?.value?.length > 0 ? (
      rest?.value?.map(normalizeValues)
    ) : (
      <span className={baseInputLabelClasses}>{placeholder}</span>
    )
  ) : rest?.value ? (
    optionsList?.find((option) => option[valueKey] === rest?.value)?.[labelKey]
  ) : (
    <span className={baseInputLabelClasses}>{placeholder}</span>
  );

  const conditioalClasses = error
    ? ' w-full border px-3 flex gap-1.5 items-center rounded-md   font-medium text-base text-grey-900 border-danger focus:border-danger ring ring-danger-100 focus:ring-danger-100 bg-danger-100 focus:bg-danger-100  xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] '
    : ` w-full border px-3 flex gap-1.5 items-center rounded-md   font-medium text-base text-grey-900 border-grey-400 focus:border-green focus:ring-green-200 bg-grey-100  xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]     } `;

  return (
    <div className="flex flex-col gap-1" ref={ref}>
      {label || required ? (
        <label className={baseInputLabelClasses}>
          {label}
          {required ? <span className="text-danger">*</span> : null}
        </label>
      ) : null}
      <Dropdown
        dropList={dropListNotification}
        showcaret={true}
        closeOnClickOutside={true}
        isLoading={isLoading}
        maxHeight={maxHeight}
      >
        <div role="input" className={conditioalClasses}>
          {values !== placeholder
            ? shouldFormat && !(typeof values === 'object')
              ? formatPhoneNumber(values)
              : values
            : values}
        </div>
      </Dropdown>
      {error ? (
        <small className="text-danger text-xs	font-normal mt-1">{error}</small>
      ) : null}
    </div>
  );
};

export default React.forwardRef(CustomSelect);
