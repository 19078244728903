import React, { useState } from 'react';
import { AsideModal, Button, Dropdown, Modal, TableManager } from 'components';
import { getBundleList } from 'api';
import AddBundleModal from 'pages/Bundle/AddBundleModal';
import { formatDateTime } from 'shared/resources';
import { dropdownActions, initialModalState } from './constants';
import { Edit, MenuIcon } from 'assets/images';
import ConfirmationModal from 'pages/Companies/ConfirmationModal';
import { useActivateDeactivateBundle } from 'hooks';

const Bundle = () => {
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [showModal, setShowModal] = useState(initialModalState);

  const {
    mutateAsync: activateDeactivateBundleMutate,
    isPending: activateDeactivateBundleLoad,
  } = useActivateDeactivateBundle({handleSuccess:handleCloseModal});

  const { isShow, modalType, type } = showModal;
  const itemElement = showModal ?? {};

  const title =
    (itemElement?.first_name || '') + ' ' + (itemElement?.last_name || '');

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  // function handleDeleteSuccess() {
  //   handleCloseModal();
  //   // queryclient.invalidateQueries(['greetingList']);
  // }

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Creation Date',
      cell: (props) => {
        const [date] = formatDateTime(props?.getValue());
        return <span>{date}</span>;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'country',
      header: () => 'Country',
      cell: (props) => (props.getValue() === 'NG' ? 'Nigeria' : ''),
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'type',
      header: () => 'Bundle Type',

      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'validity',
      header: () => 'Validity',

      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'total_bundles',
      header: () => 'Total Bundle',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'cost',
      header: () => 'Cost',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        return props?.getValue() === 1 ? 'Activated' : 'Deactivated';
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original || {};
        return (
          <div className="flex gap-2 items-center">
            <Dropdown
              menuWidth="w-max"
              maxHeight={false}
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => {
                  const Icon = item?.icon ?? null;
                  const del = item?.type === 'del' ? 'text-danger' : '';
                  if (item.access) {
                    return (
                      <span
                        className={`w-full h-full flex justify-start gap-2 items-center py-2 px-3 ${del}`}
                        onClick={() => handleDropListClick(item, element)}
                      >
                        {Icon} {item?.title}
                      </span>
                    );
                  }
                },
                data: [
                  {
                    id: 1,
                    title: element?.status ? 'Deactivate' : 'Activate',
                    value: element?.status
                      ? dropdownActions.DEACTIVATE_BUNDLE
                      : dropdownActions.ACTIVATE_BUNDLE,
                    icon: <Edit className="text-grey-700" />,
                    access: true,
                  },
                ],
              }}
              showcaret={false}
            >
              <div className="cursor-pointer w-1">
                <MenuIcon />
              </div>
            </Dropdown>
          </div>
        );
      },

      meta: {
        colType: 'action',
        width: '5%',
        hasAccess: true,
      },
    },
  ];

  const ModalLookup = {
    [dropdownActions.ADD_BUNDLE]: (
      <AddBundleModal
        handleClose={() => setShowModal(initialModalState)}
        showModal={showModal}
      />
    ),
    [dropdownActions.ACTIVATE_BUNDLE]: (
      <ConfirmationModal
        title={title}
        showModal={showModal}
        handleClose={handleCloseModal}
        activateDeactivateFn={activateDeactivateBundleMutate}
        isLoading={activateDeactivateBundleLoad}
        statusParams={true}
      />
    ),
    [dropdownActions.DEACTIVATE_BUNDLE]: (
      <ConfirmationModal
        title={title}
        showModal={showModal}
        handleClose={handleCloseModal}
        activateDeactivateFn={activateDeactivateBundleMutate}
        isLoading={activateDeactivateBundleLoad}
        statusParams={true}
      />
    ),
  };

  const tableActions = [
    {
      id: 1,
      label: 'Add Bundle',
      access: true,
      component: (
        <div className="w-64">
          <Button
            type="button"
            height="h-10"
            onClick={() =>
              setShowModal({
                isShow: true,
                modalType: dropdownActions.ADD_BUNDLE,
                elementData: {},
              })
            }
          >
            Add Bundle +
          </Button>
        </div>
      ),
    },
  ];

  const filterInitialValues = {
    filter: [
      {
        plan_name: '',
        a_filter_type: '',
        placeholder: 'Enter Plan',
        type: 'text',
        shouldFilterOptionDisabled: false,
        label: 'Plan',
        filterName: 'plan_name',
        position: 1,
      },
    ],
  };

  const handleDropListClick = ({ value = '' }, element = {}) => {
    switch (value) {
      case dropdownActions.ADD_BUNDLE:
        setShowModal({ isShow: true, modalType: value, elementData: element });
        break;
      case dropdownActions.ACTIVATE_BUNDLE:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'center',
        });
        break;
      case dropdownActions.DEACTIVATE_BUNDLE:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'center',
        });
        // setShowModal({ isShow: false });
        // deactivateSubAdminMutate({ uuid: element?.uuid });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mb-5"></div>
      <TableManager
        fetcherFn={getBundleList}
        fetcherKey={'getBundleList'}
        searchPlaceholder={'Search'}
        searchLabel=""
        isSearchable={true}
        shouldFilter={true}
        columns={columns}
        tableActions={tableActions}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
      />
      {isShow && (
        <>
          {type === 'center' ? (
            <Modal
              handleClose={handleCloseModal}
              headerComponent={null}
              footerComponent={null}
              shouldCloseOnClickOutside={false}
            >
              {ModalLookup[modalType]}
            </Modal>
          ) : (
            <AsideModal
              handleClose={() => setShowModal(initialModalState)}
              footerComponent={null}
              headerComponent={null}
              shouldCloseOnClickOutside={true}
            >
              {ModalLookup[modalType]}
            </AsideModal>
          )}
        </>
      )}
    </>
  );
};

export default Bundle;
