import { Check, X } from "assets/images";
import { Button, ReadViewInput } from "components";
import { useUpdateKYC } from "hooks";
import React, { useState } from "react";

const KYCSuccess = ({ kycData = {}, kycJson = {} }) => {
  const [isRejected, setIsRejected] = useState(false);
  const [reason, setRejectionReason] = useState("");
  const { mutate: updateKYCMutate, isPending: updateKYCLoad } = useUpdateKYC({
    handleSuccess: () => setIsRejected(false),
  });

  function handleRejection(status) {
    if (!reason) return;
    updateKYCMutate({ status, rejection_reason: reason, uuid: kycData?.uuid });
  }

  function handleApprove(status) {
    updateKYCMutate({ status, uuid: kycData?.uuid });
  }

  function handleResponse(status) {
    switch (status) {
      case "COMPLETED":
        return (
          <div className="text-sm mt-5 font-semibold gap-2 py-3 bg-green-100 text-green rounded-full flex justify-center items-center w-full">
            KYC completed successfully
            <div>
              <Check />
            </div>
          </div>
        );
      case "REJECTED":
        return (
          <div className="text-sm mt-5 font-semibold gap-2 py-3 bg-danger-100 text-danger rounded-full flex justify-center items-center w-full">
            <X /> Your KYC is rejected because of this reason
            <div>{`"${kycData?.rejection_reason ?? ""}"`}</div>
          </div>
        );

      default:
        break;
    }
  }
  const renderJson =
    kycJson && Object.keys(kycJson).length > 0
      ? Object.entries(kycJson).map(([key, value]) => {
          if (key === "photo") {
            return (
              <div
                className="flex gap-1 overflow-y-auto max-w-[700px] w-full "
                key={key}
              >
                <label
                  className={`text-grey-800 min-w-150 xxl:text-base xl:text-sm lg:text-sm md:text-xs font-medium capitalize`}
                >
                  {key?.toLowerCase()?.split("_")?.join(" ")}
                </label>
                <img
                  className="rounded-md w-52 min-w-[200px] h-32 object-contain bg-black"
                  src={`data:image/*;base64,${value}`}
                  alt="img"
                />
              </div>
            );
          }

          return (
            key !== "faceResult" && (
              <ReadViewInput key={key} labelText={key} valueText={value} />
            )
          );
        })
      : null;

  return (
    <div className="w-full max-w-[700px]">
      <div className="text-lg font-semibold">API Response</div>
      <div className="w-full flex gap-4 flex-col">
        <div className="rounded-md overflow-auto h-full min-h-[calc(100vh_-_454px)]">
          <div className="w-full p-3 h-full rounded-md text-white break-words flex gap-4 flex-col">
            {renderJson}
          </div>
        </div>
      </div>

      {handleResponse(kycData?.status)}

      {!["REJECTED", "COMPLETED", "SUBMITTED"].includes(kycData.status) ? (
        <div className="w-full mt-2 flex gap-2">
          <Button
            type="button"
            onClick={() => handleApprove("COMPLETED")}
            disabled={updateKYCLoad}
          >
            Verified
          </Button>
          <Button
            background="danger"
            type="button"
            onClick={() => setIsRejected(true)}
          >
            Rejected
          </Button>
        </div>
      ) : null}

      {isRejected ? (
        <>
          <div className="xxl:mt-5 xl:mt-5 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4 w-full">
            <label className="text-grey-800 text-base font-medium">
              Rejection Reason
            </label>
            <textarea
              className={`w-full border bg-transparent rounded-md focus:outline-none focus:ring focus:bg-white font-medium text-base text-grey-900  xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] ${
                reason
                  ? "border-grey-400 focus:border-green focus:ring-green-200"
                  : "border-danger focus:border-danger ring ring-danger-100 focus:ring-danger-100 bg-danger-100 focus:bg-danger-100"
              }`}
              placeholder="Write reason"
              value={reason}
              onChange={(e) => setRejectionReason(e?.target?.value)}
              id="rejection_reason"
              rows={6}
            ></textarea>
            <div className="w-full mt-2 flex gap-2">
              <Button
                background="danger"
                type="button"
                onClick={() => handleRejection("REJECTED")}
                disabled={updateKYCLoad}
              >
                {updateKYCLoad ? "Please wait..." : "Reject"}
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default KYCSuccess;
