export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};

export const dropdownActions = {
  ADD_SUB_ADMIN: 'add_sub_admin',
  DELETE_SUB_ADMIN: 'delete_sub_admin',
  ACTIVATE_SUB_ADMIN: 'activate_sub_admin',
  DEACTIVATE_SUB_ADMIN: 'de_activate_sub_admin',
};
