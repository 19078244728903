import React, { useState } from 'react';
import { AsideModal, Button, Dropdown, Modal, TableManager } from 'components';
import { getSubAdminList } from 'api';
import AddSubAdmin from 'pages/SubAdmin/AddSubAdminModal';
import { IdPathMap, formatDateTime } from 'shared/resources';
import { dropdownActions, initialModalState } from './constants';
import { Edit, MenuIcon, Trash } from 'assets/images';
import { useActivateSubAdmin, useDeactivateSubAdmin, useDeleteSubAdmin } from 'hooks';
import DeleteSubAdminModal from './DeleteSubAdminModal';
import ConfirmationModal from 'pages/Companies/ConfirmationModal';

function SubAdmin() {
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [showModal, setShowModal] = useState(initialModalState);
  const { mutate: deleteSubAdminMutate, isPending: deleteSubAdminLoad } =
    useDeleteSubAdmin({ handleSuccess: handleDeleteSuccess });
  const {
    mutateAsync: activateSubAdminMutate,
    isPending: activateCompanyLoad,
  } = useActivateSubAdmin({handleSuccess:handleCloseModal});
  const {
    mutateAsync: deactivateSubAdminMutate,
    isPending: deactivateCompanyLoad,
  } = useDeactivateSubAdmin({handleSuccess:handleCloseModal});

  const { isShow, modalType, type } = showModal;
  const itemElement = showModal?.elementData ?? {};
  const title = (itemElement?.first_name || '') + ' ' + (itemElement?.last_name || '');

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  function handleDeleteSuccess() {
    handleCloseModal();
  }

  const ModalLookup = {
    [dropdownActions.ADD_SUB_ADMIN]: (
      <AddSubAdmin
        handleClose={() => setShowModal(initialModalState)}
        showModal={showModal}
      />
    ),
    [dropdownActions.DELETE_SUB_ADMIN]: (
      <DeleteSubAdminModal
        showModal={showModal}
        handleClose={handleCloseModal}
        deleteFn={deleteSubAdminMutate}
        isLoading={deleteSubAdminLoad}
      />
    ),
    [dropdownActions.ACTIVATE_SUB_ADMIN]: (
      <ConfirmationModal
        title={title}
        showModal={showModal}
        handleClose={handleCloseModal}
        activateDeactivateFn={activateSubAdminMutate}
        isLoading={activateCompanyLoad}
      />
    ),
    [dropdownActions.DEACTIVATE_SUB_ADMIN]: (
      <ConfirmationModal
        title={title}
        showModal={showModal}
        handleClose={handleCloseModal}
        activateDeactivateFn={deactivateSubAdminMutate}
        isLoading={deactivateCompanyLoad}
      />
    ),
  };

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Creation Date',
      cell: (props) => {
        const [date] = formatDateTime(props?.getValue());
        return <span>{date}</span>;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'first_name',
      header: () => 'First Name',

      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'last_name',
      header: () => 'Last Name',

      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',

      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'access_permissions',
      header: () => 'Access Permission',
      cell: (props) => {
        if (!props || props?.length <= 0) return '---';
        return (props?.getValue()?.map((val) => {
              return IdPathMap[val];
            })
            ?.join(', ') ?? '---'
        );
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        return props?.getValue() === 1 ? 'Activated' : 'Deactivated';
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original || {};
        return (
          <div className="flex gap-2 items-center">
            <Dropdown
              menuWidth="w-max"
              maxHeight={false}
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => {
                  const Icon = item?.icon ?? null;
                  const del = item?.type === 'del' ? 'text-danger' : '';
                  if (item.access) {
                    return (
                      <span
                        className={`w-full h-full flex justify-start gap-2 items-center py-2 px-3 ${del}`}
                        onClick={() => handleDropListClick(item, element)}
                      >
                        {Icon} {item?.title}
                      </span>
                    );
                  }
                },
                data: [
                  {
                    id: 1,
                    title: 'Edit Sub Admin',
                    value: dropdownActions.ADD_SUB_ADMIN,
                    icon: <Edit className="text-grey-700" />,
                    access: true,
                  },
                  {
                    id: 2,
                    title: element?.status ? 'Deactivate' : 'Activate',
                    value: element?.status
                      ? dropdownActions.DEACTIVATE_SUB_ADMIN
                      : dropdownActions.ACTIVATE_SUB_ADMIN,
                    icon: <Edit className="text-grey-700" />,
                    access: true,
                  },
                  {
                    id: 3,
                    title: 'Delete Sub Admin',
                    value: dropdownActions.DELETE_SUB_ADMIN,
                    icon: <Trash className=" text-danger " />,
                    access: true,
                    type: 'del',
                  },
                ],
              }}
              showcaret={false}
            >
              <div className="cursor-pointer w-1">
                <MenuIcon />
              </div>
            </Dropdown>
          </div>
        );
      },

      meta: {
        colType: 'action',
        width: '5%',
        hasAccess: true,
      },
    },
  ];

  const tableActions = [
    {
      id: 1,
      label: 'Add Sub Admin',
      access: true,
      component: (
        <div className="w-64">
          <Button
            type="button"
            height="h-10"
            onClick={() =>
              setShowModal({
                isShow: true,
                modalType: dropdownActions.ADD_SUB_ADMIN,
                elementData: {},
              })
            }
          >
            Add Sub Admin +
          </Button>
        </div>
      ),
    },
  ];

  const filterInitialValues = {
    filter: [
      {
        plan_name: '',
        a_filter_type: '',
        placeholder: 'Enter Plan',
        type: 'text',
        shouldFilterOptionDisabled: false,
        label: 'Plan',
        filterName: 'plan_name',
        position: 1,
      },
    ],
  };

  const handleDropListClick = ({ value = '' }, element = {}) => {
    switch (value) {
      case dropdownActions.ADD_SUB_ADMIN:
        setShowModal({ isShow: true, modalType: value, elementData: element });
        break;
      case dropdownActions.DELETE_SUB_ADMIN:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'center',
        });
        break;
      case dropdownActions.ACTIVATE_SUB_ADMIN:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'center',
        });
        // setShowModal({ isShow: false });
        // activateSubAdminMutate({ uuid: element?.uuid });
        break;
      case dropdownActions.DEACTIVATE_SUB_ADMIN:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'center',
        });
        // setShowModal({ isShow: false });
        // deactivateSubAdminMutate({ uuid: element?.uuid });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mb-5"></div>
      <TableManager
        fetcherFn={getSubAdminList}
        fetcherKey={'getSubAdminList'}
        searchPlaceholder={'Search '}
        searchLabel=""
        isSearchable={true}
        shouldFilter={true}
        columns={columns}
        tableActions={tableActions}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
      />
      {isShow && (
        <>
          {type === 'center' ? (
            <Modal
              handleClose={handleCloseModal}
              headerComponent={null}
              footerComponent={null}
              shouldCloseOnClickOutside={false}
            >
              {ModalLookup[modalType]}
            </Modal>
          ) : (
            <AsideModal
              handleClose={() => setShowModal(initialModalState)}
              footerComponent={null}
              headerComponent={null}
              shouldCloseOnClickOutside={true}
            >
              {ModalLookup[modalType]}
            </AsideModal>
          )}
        </>
      )}
    </>
  );
}

export default SubAdmin;
