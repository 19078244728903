import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from 'components';
import CustomSelect from 'components/CustomSelect';
import { useIVRMutate } from 'hooks';
import useUpdateIVRMutate from 'hooks/useUpdateIVRMutate';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ayt } from 'shared/resources';
import * as yup from 'yup';

const ItemComponent = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="flex items-start gap-3 py-2 px-3"
    >
      <div className="flex flex-col items-start gap-1.5 ">
        <div className="text-black font-medium text-base">{item?.label}</div>
      </div>
    </div>
  );
};

const initialValues = {
  did_number: '',
  monthly_cost: '',
  did_type: '',
  // did_country: '',
  country: 'NG',
  did_city: '',
};

const validationSchema = yup.object().shape({
  did_number: yup.string().required('DID number is required'),
  monthly_cost: yup.string().required('Monthly Cost is required'),
  did_type: yup.string().required('DID type is required'),
  // did_country: yup.string().required(getRequiredText('DID country')),
  // did_city: yup.string().required(getRequiredText('DID city')),
});

const AddVirtualNumber = ({ showModal = {}, handleClose = () => null }) => {
  const { elementData = {} } = showModal;
  const isEdit = elementData && Object.keys(elementData)?.length > 0;
  const { mutate: createIVRMutate, isPending: createIVRMutateLoad } =
    useIVRMutate({
      handleSuccess,
    });

  const { mutate: updateIVRMutate, isPending: updateIVRMutateLoad } =
    useUpdateIVRMutate({
      handleSuccess,
    });
  function handleSuccess() {
    handleClose();
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (isEdit && elementData && Object.keys(elementData).length > 0) {
      setValue('did_number', elementData?.did_number);
      setValue('monthly_cost', elementData?.monthly_cost);
      setValue('did_type', elementData?.did_type);
      setValue('area_code', elementData?.area_code);
      setValue('country', 'NG');
      setValue('did_city', elementData?.did_city);
    }
  }, [isEdit]);

  const onSubmit = (values) => {
    if (isEdit) {
      updateIVRMutate({
        uuid: elementData?.uuid,
        did_number: values?.did_number,
        country: 'NG',
        did_city: values?.did_city,
        area_code: values?.area_code,
        monthly_cost: values?.monthly_cost,
        did_type: values?.did_type,
      });
    } else {
      const payload = {
        did_number: values?.did_number,
        country: 'NG',
        did_city: values?.did_city,
        monthly_cost: values?.monthly_cost,
        did_type: values?.did_type,
        area_code: values?.area_code,
      };
      createIVRMutate(payload);
    }
  };
  return (
    <div className="min-w-[500px] overflow-auto   ">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {isEdit ? 'Update Virtual Number' : 'Add Virtual Number'}
          </div>
          <div className="text-sm  text-grey-700">
            {isEdit
              ? 'Edit the information to update'
              : 'Enter the information to add a new virtual number'}
          </div>
        </div>
      </div>
      <div className=" pb-5 pt-6 ">
        <form
          className="overflow-auto px-6 h-[calc(100vh_-_120px)]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-4">
            <div>
              <Controller
                name="did_number"
                control={control}
                render={({ field }) => {
                  const { onChange } = field;
                  return (
                    <Input
                      {...field}
                      onWhitebg={true}
                      label="DID Number"
                      error={errors?.did_number?.message}
                      autoComplete="off"
                      maxLength="17"
                      onChange={(e) => {
                        const aytNumber = ayt(e.target.value);
                        onChange(aytNumber);
                      }}
                      required={true}
                    />
                  );
                }}
              />
            </div>
            <div className="mb-2">
              <Controller
                name="did_type"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    height="xxl:h-14 xl:h-12"
                    placeholder="Select"
                    onWhitebg={true}
                    label="DID Type"
                    error={errors?.role_id?.message}
                    ItemComponent={ItemComponent}
                    valueKey={'value'}
                    labelKey={'label'}
                    isMulti={false}
                    maxHeight={false}
                    optionsList={[
                      { value: 'T', label: 'Toll free' },
                      { value: 'L', label: 'Local' },
                      { value: 'N', label: 'National' },
                    ]}
                    required={true}
                  />
                )}
              />
              <p className="text-danger text-xs	font-normal mt-1">
                {errors?.did_type?.message}
              </p>
            </div>
            <div>
              <Controller
                name="monthly_cost"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Monthly Cost"
                    // type="number"
                    error={errors?.monthly_cost?.message}
                    maxLength={50}
                    required={true}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Country"
                    error={errors?.email?.message}
                    maxLength={50}
                    disabled={isEdit}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="area_code"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Area Code"
                    maxLength={50}
                    disabled={isEdit}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="did_city"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="DID City"
                    // error={errors?.email?.message}
                    maxLength={50}
                    disabled={isEdit}
                  />
                )}
              />
            </div>
          </div>
          <div className=" mt-5">
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={createIVRMutateLoad}
            >
              {createIVRMutateLoad || updateIVRMutateLoad
                ? 'Please wait '
                : 'Submit'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVirtualNumber;
