import { Input } from 'components';

function Settings() {
  return (
    <div className="p-5">
      <div className="flex items-center mb-3">
        <label className="font-semibold w-12">Title</label>
        <div className="flex flex-col w-30">
          <Input className="py-1 px-2 border rounded" />
        </div>
      </div>
      <div className="flex items-center">
        <label className="font-semibold w-12">Name</label>
        <div className="flex flex-col w-30">
          <Input className="py-1 px-2 border rounded" />
        </div>
      </div>
    </div>
  );
}

export default Settings;
