import React from 'react';
import { TableManager } from 'components';
import { getKycUsersList } from 'api';
// import { colorLookup, } from 'shared/resources';
import { useParams } from 'react-router-dom';
import { rolesArray } from 'shared/resources';

function Users() {
  const { id: company_uuid } = useParams();

  const columns = [
    {
      accessorKey: 'first_name',
      header: () => 'Name',
      cell: (props) =>
        `${props?.row?.original?.first_name} ${props?.row?.original?.last_name}`,
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'role_id',
      header: () => 'Role',
      cell: (props) => {
        const obj = rolesArray.find((val) => val.value === props?.getValue());
        return obj?.label;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) =>
        props?.row?.original?.status === 1 ? (
          <span>Active</span>
        ) : (
          <span>Deactivate</span>
        ),
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between mb-5"></div>

      <TableManager
        fetcherFn={getKycUsersList}
        fetcherKey={'getKycUsersList'}
        // searchPlaceholder={'Search'}
        // searchLabel=""
        // isSearchable={true}
        // shouldFilter={true}
        title="Users"
        extraParams={{ company_uuid }}
        columns={columns}
      />
    </>
  );
}

export default Users;
