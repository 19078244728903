import { Arrow } from 'assets/images';
import { Avatar } from 'components';
import React from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';


const CompanyDetailLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const activePath = location?.pathname?.split('/')?.[3];

  const routes = [
    {
      id: 1,
      label: 'KYC',
      value: 'kyc',
      path: `kyc/${location?.state?.kyc_verification_details?.uuid}`,
    },
    {
      id: 2,
      label: 'Report',
      value: 'report',
      path: "report",
    },
    {
      id: 3,
      label: 'Payments',
      value: 'payments',
      path: "payments",
    },
    {
      id: 4,
      label: 'Users',
      value: 'users',
      path: "users",
    },
  ];


  return (
    <div className="bg-grey-100 flex h-full overflow-auto">
      <div className="xxl:min-w-[290px] xl:min-w-[250px] lg:min-w-[256px] md:min-w-[230px] pt-10 px-4 flex flex-col gap-2 border-grey-300 border-r">
        <div className="flex gap-2 items-center mb-2">
          <div
            className="w-5 h-5 rounded-full flex items-center justify-center cursor-pointer"
            onClick={() => navigate('/companies')}
          >
            <Arrow width={18} height={18} className="rotate-180" />
          </div>
          <Avatar
            name={`${location?.state?.user?.first_name} ${location?.state?.user?.last_name}`}
            mediaUrl=""
          />
          <div className="flex flex-col">
            <div className="text-sm font-semibold">{`${location?.state?.user?.first_name} ${location?.state?.user?.last_name}`}</div>
            <div className="text-xs text-grey-600">
              {location?.state?.user?.email}
            </div>
          </div>
        </div>
        {routes?.map((nav) => {
       let path = nav?.path?.split("/")?.[0]
          return (
            <Link
              key={nav?.id}
              to={`/company-info/${location?.state?.uuid}/${nav?.path}`}
              className={`xxl:text-base xl:text-base lg:text-sm md:text-sm flex items-center gap-3 p-3 rounded-md hover:bg-grey-300 cursor-pointer ${
                path === activePath ? 'bg-grey-300' : ''
              }`}
              state={location?.state}
            >
              {nav?.label}
            </Link>
          );
        })}
      </div>
      <div className='px-3 bg-white w-full overflow-auto'>
      <Outlet />
      </div>
    </div>
  );
};

export default CompanyDetailLayout;
