import Access from 'auth/Access';
import CompanyDetailLayout from 'layouts/CompanyDetailLayout';
import {
  Calls,
  Dashboard,
  Login,
  PageNotFound,
  Plan,
  SMS,
  Transaction,
  Unauthorized,
  VirtualNumber,
  Settings,
  SubAdmin,
  Companies,
  Bundle,
  KYC,
  Payments,
  Users,
} from 'pages';
import KYCReadView from 'pages/CompanyInfo/KYC';
import Reports from 'pages/CompanyInfo/Reports';
import Profile from 'pages/Profile/Profile';
import ChangePassword from 'pages/Profile/Profile/ChangePassword';
import ProfileLayout from 'pages/Profile/Profile/Layout';
import { lazy } from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';

const AuthRemover = lazy(() => import('auth/AuthRemover'));
const AuthProvider = lazy(() => import('auth/AuthProvider'));
const AuthLayout = lazy(() => import('layouts/AuthLayout'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthRemover>
        <Outlet />
      </AuthRemover>
    ),
    children: [
      {
        index: true,
        element: <Login />,
        id: 'Login',
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthProvider>
        <AuthLayout />
      </AuthProvider>
    ),
    children: [
      {
        path: 'dashboard',
        element: (
          <Access pathId={1}>
            <Dashboard />
          </Access>
        ),
        id: 'Dashboard',
      },
      {
        path: 'sub-admin',
        element: (
          <Access pathId={2}>
            <SubAdmin />
          </Access>
        ),
        id: 'SubAdmin',
      },
      {
        path: 'companies',
        element: (
          <Access pathId={3}>
            <Companies />
          </Access>
        ),
        id: 'Companies',
      },
      {
        path: 'company-info/:id',
        element: (
          <AuthProvider>
            <CompanyDetailLayout />
          </AuthProvider>
        ),
        children: [
          {
            path: 'kyc/:kycId',
            element: <KYCReadView />,
          },
          {
            path: 'report',
            element: <Reports />,
          },
          {
            path: 'payments',
            element: <Payments />,
          },
          {
            path: 'users',
            element: <Users />,
          },
        ],
      },
      {
        path: 'virtual-numbers',
        element: (
          <Access pathId={4}>
            <VirtualNumber />
          </Access>
        ),
        id: 'VirtualNumber',
      },
      {
        path: 'calls',
        element: (
          <Access pathId={6}>
            <Calls />
          </Access>
        ),
        id: 'Calls',
      },
      {
        path: 'transaction',
        element: (
          <Access pathId={5}>
            <Transaction />
          </Access>
        ),
        id: 'Transaction',
      },
      {
        path: 'sms',
        element: (
          <Access pathId={7}>
            <SMS />
          </Access>
        ),
        id: 'SMS',
      },
      {
        path: 'plan',
        element: (
          <Access pathId={8}>
            <Plan />
          </Access>
        ),
        id: 'Plan',
      },
      {
        path: 'profile',
        element: <ProfileLayout />,
        children: [
          {
            path: 'general',
            element: <Profile />,
          },
          {
            path: 'changepassword',
            element: <ChangePassword />,
          },
        ],
        id: 'Profile',
      },
      {
        path: 'settings',
        element: (
          <Access pathId={9}>
            <Settings />
          </Access>
        ),
        id: 'Settings',
      },
      {
        path: 'bundle',
        element: (
          <Access pathId={10}>
            <Bundle />
          </Access>
        ),
        id: 'Bundle',
      },
      {
        path: 'kyc',
        element: (
          <Access pathId={11}>
            <KYC />
          </Access>
        ),
        id: 'kyc',
      },
    ],
  },

  {
    path: '/unauthorized',
    element: <Unauthorized />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);
