export const routes = {
  LOGIN: {
    METHOD: 'POST',
    URL: 'admin/auth/login',
  },
  TENANT_LISTING: {
    METHOD: 'GET',
    URL: 'listing',
  },
  UPDATE_SUB_ADMIN: {
    METHOD: 'POST',
    URL: 'admin/auth/update-sub-admin',
  },
  UPDATE_IVR_NUMBER: {
    METHOD: 'POST',
    URL: 'admin/did/update',
  },
  DELETE_SUB_ADMIN: {
    METHOD: 'DELETE',
    URL: 'admin/auth/delete-sub-admin',
  },
  DELETE_VIRTUAL_NUMBER: {
    METHOD: 'DELETE',
    URL: 'admin/did/delete',
  },
  COMPANY_LIST: {
    METHOD: 'POST',
    URL: 'admin/company/companies-list',
  },
  ADD_COMPANY: {
    METHOD: 'POST',
    URL: 'admin/company/add-company',
  },
  VIRTUAL_NUMBER: {
    METHOD: 'POST',
    URL: 'admin/did/add',
  },
  UPDATE_COMPANY: {
    METHOD: 'POST',
    URL: 'admin/company/update-company',
  },
  ACTIVATE_COMPANY: {
    METHOD: 'POST',
    URL: 'admin/company/activate-company',
  },
  DEACTIVATE_COMPANY: {
    METHOD: 'POST',
    URL: 'admin/company/deactivate-company',
  },
  GET_ADMIN_DETAILS: {
    METHOD: 'GET',
    URL: 'admin/auth/get-admin-details',
  },
  SUB_ADMIN_LIST: {
    METHOD: 'POST',
    URL: 'admin/auth/sub-admin-list',
  },
  ADD_SUB_ADMIN: {
    METHOD: 'POST',
    URL: 'admin/auth/add-sub-admin',
  },
  ACTIVATE_SUB_ADMIN: {
    METHOD: 'POST',
    URL: 'admin/auth/activate-sub-admin',
  },
  DEACTIVATE_SUB_ADMIN: {
    METHOD: 'POST',
    URL: 'admin/auth/deactivate-sub-admin',
  },
  VIRTUAL_NUMBERS: {
    METHOD: 'POST',
    URL: 'admin/did/list',
  },
  PORT_REQUESTS_LISTING: {
    METHOD: 'GET',
    URL: 'listing',
  },
  TRANSACTION_LISTING: {
    METHOD: 'POST',
    URL: 'admin/transaction/transaction-list',
  },
  CALLS_LISTING: {
    METHOD: 'GET',
    URL: 'listing',
  },
  GET_SMS_LISTING: {
    METHOD: 'GET',
    URL: 'listing',
  },
  PLAN_LISTING: {
    METHOD: 'POST',
    URL: 'admin/plan/list',
  },
  UPDATE_PLAN: {
    METHOD: 'POST',
    URL: 'admin/plan/update',
  },
  GET_PROVIDER_LISTING: {
    METHOD: 'GET',
    URL: 'listing',
  },
  RATE_CARD_LISTING: {
    METHOD: 'GET',
    URL: 'listing',
  },
  SUPPORT_LISTING: {
    METHOD: 'GET',
    URL: 'listing',
  },
  BUNDLE_LISTING: {
    METHOD: 'POST',
    URL: 'admin/bundles/list',
  },
  ADD_BUNDLE: {
    METHOD: 'POST',
    URL: 'admin/bundles/add',
  },
  BUNDLE_ACTIVATE_DEACTIVATE: {
    METHOD: 'POST',
    URL: 'admin/bundles/activate-deactivate',
  },
  KYC_LISTING: {
    METHOD: 'POST',
    URL: 'admin/kyc/list',
  },
  UPDATE_KYC: {
    METHOD: 'POST',
    URL: 'admin/kyc/update',
  },
  KYC_VERIFICATION: {
    METHOD: 'POST',
    URL: 'admin/kyc/verification-data',
  },
  GET_KYC_DETAILS: {
    METHOD: 'POST',
    URL: 'admin/kyc/get-details',
  },
  REPORT_KYC_LOGS: {
    METHOD: 'POST',
    URL: 'admin/kyc/logs',
  },
  REPORT_KYC_PAYMENTS: {
    METHOD: 'POST',
    URL: 'admin/company/transactions-list',
  },
  REPORT_KYC_USERS: {
    METHOD: 'POST',
    URL: 'admin/company/users-list',
  },
  UPLOAD_MEDIA: {
    METHOD: 'POST',
    URL: `upload-url`,
  },
};
