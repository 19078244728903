import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deactivateCompany } from '../../api';
import { toastEmitter } from 'components/Toast';

export default function useDeactivateCompany({
  handleSuccess = () => null,
} = {}) {
  const queryclient = useQueryClient();

  const Request = useMutation({
    mutationFn: deactivateCompany,
    mutationKey: 'deactivateCompany',
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      toastEmitter('success', responseData?.data?.message);
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }
      queryclient.invalidateQueries(['getCompanyList']);
      handleSuccess();
    },
  });
  return { ...Request };
}
