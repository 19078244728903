import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Spinner } from 'components';
import { useAuth } from 'hooks/useAuth';
import { Facebook, Instagram, Logo, Twitter } from 'assets/images';
import { useAddCompany, useUpdateCompany } from 'hooks';
import useUploadFile from 'hooks/useUploadFile';
import { toastEmitter } from 'components/Toast';
import { ayt, getRequiredText } from 'shared/resources';

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  phone: '',
  company_name: '',
  company_logo: null,
  website_link: '',
  facebook_link: '',
  twitter_link: '',
  instagram_link: '',
};

const validationSchema = yup.object({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup
    .string()
    .required(getRequiredText('Email'))
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter valid email address',
    ),
  password: yup.string().required(getRequiredText('Password')),
  phone: yup.string().required('Phone number is required'),
  company_name: yup.string().required('Company name is required'),
  company_logo: yup.string().required('Company logo is required'),
});

const AddCompanyModal = ({ handleClose = () => null, showModal = {} }) => {
  const { elementData = {} } = showModal;
  const isEdit = elementData && Object.keys(elementData)?.length > 0;
  const { user } = useAuth();
  const [iconPreview, setIconPreview] = useState(null);
  const [loadingIcon, setLoadingIcon] = useState(true);
  const { mutate: updateCompanyMutate, isPending: updateCompanyLoad } =
    useUpdateCompany({ handleSuccess });
  const { mutate: addCompanyMutate, isPending: addCompanyLoad } = useAddCompany(
    { handleSuccess },
  );
  const { uploadMedia, isLoading: logoUploadLoading } = useUploadFile();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });
  console.log(errors);
  useEffect(() => {
    if (isEdit && elementData && Object.keys(elementData).length > 0) {
      setValue('first_name', elementData?.user?.first_name);
      setValue('last_name', elementData?.user?.last_name);
      setValue('email', elementData?.user?.email);
      setValue('phone', elementData?.user?.phone);
      setValue('company_name', elementData?.company_name);
      setValue('company_logo', elementData?.company_logo);
      setValue('website_link', elementData?.website_link);
      setValue('facebook_link', elementData?.facebook_link);
      setValue('twitter_link', elementData?.twitter_link);
      setValue('instagram_link', elementData?.instagram_link);
      setValue('name', watch('company_logo'));
      const result = `${process.env.REACT_APP_BASE_API_URL}${user?.uuid}/company_logo/${elementData?.company_logo}`;
      setIconPreview(result);
    }
  }, [isEdit]);

  const onSubmit = async (values) => {
    console.log('here');
    let url;
    try {
      const res = await uploadMedia({
        type: 'company_logo',
        file: watch('company_logo'),
      });
      if (!res?.file_name)
        return toastEmitter('error', 'Please upload upload icon.');
      url = res.file_name;
    } catch (err) {
      return toastEmitter('error', 'Unable to upload icon.');
    }
    const payload = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
      password: values?.password,
      phone: values?.phone,
      company_name: values?.company_name,
      company_logo: url,
      website_link: values?.website_link,
      facebook_link: values?.facebook_link,
      twitter_link: values?.twitter_link,
      instagram_link: values?.instagram_link,
    };

    if (isEdit) {
      updateCompanyMutate({ uuid: elementData?.uuid, ...payload });
    } else {
      addCompanyMutate({ reg_type: 'WEB', ...payload });
    }
  };

  function handleSuccess() {
    handleClose();
  }

  useEffect(() => {
    if (watch('company_logo') && watch('company_logo')?.name) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setIconPreview(event.target.result);
      };
      reader.readAsDataURL(watch('company_logo'));
    }
  }, [watch('company_logo')?.name]);

  // useEffect(() => {
  //   if (watch('company_logo') && typeof watch('company_logo') === 'string') {
  //     const result = `${process.env.REACT_APP_BASE_API_URL}${user?.uuid}/icon/${watch('company_logo')}`;
  //     setIconPreview(result);
  //   }
  // }, [watch('company_logo')]);

  return (
    <div className="pl-10 pt-4 pr-8 overflow-y-auto max-h-screen w-[640px] p-5">
      <div className="text-xl font-semibold text-black mb-2">
        {isEdit ? 'Update Company' : 'Add Company'}
      </div>
      <form
        className="w--full d--flex flex--column gap--md"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-3 sm:mt-3 xs:mt-4">
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required={true}
                label="First Name"
                error={errors?.first_name?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-3 sm:mt-3 xs:mt-4">
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required={true}
                label="Last Name"
                error={errors?.last_name?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required={true}
                label="Email"
                error={errors?.email?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                maxLength="10"
                required={true}
                label="Password"
                error={errors?.password?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="phone"
            control={control}
            render={({ field }) => {
              const { onChange } = field;
              return (
                <Input
                  {...field}
                  label="Phone Number"
                  autoComplete="off"
                  maxLength="17"
                  onChange={(e) => {
                    const aytNumber = ayt(e.target.value);
                    onChange(aytNumber);
                  }}
                  error={errors?.phone?.message}
                />
              );
            }}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-3 sm:mt-3 xs:mt-4">
          <Controller
            name="company_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required={true}
                label="Company Name"
                error={errors?.company_name?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <div className="flex flex-col">
            <div className="font--medium text-base text-grey-900">
              Company Logo
            </div>
            <div className="flex items-center w-full gap-2 mt-2">
              <div className="">
                <Controller
                  name="company_logo"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <label
                        className="w-[76px] xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] flex items-center justify-center bg-grey-100 border-grey-400 border rounded-md cursor-pointer"
                        htmlFor="company_logo"
                      >
                        <div className="w-6 h-6">
                          {watch('company_logo') ? (
                            <div className="flex items-center justify-center">
                              <img
                                className="w-8 h-8 hidden"
                                src={iconPreview}
                                alt="icon-preview"
                                onLoad={() => {
                                  setLoadingIcon(false);
                                }}
                                onError={() => setLoadingIcon(false)}
                              />
                              {loadingIcon ? (
                                <Spinner />
                              ) : (
                                <img
                                  className="w-6 h-6"
                                  src={iconPreview}
                                  alt="icon-preview"
                                />
                              )}
                            </div>
                          ) : (
                            <Logo />
                          )}
                        </div>
                        <input
                          id="company_logo"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            field.onChange(e.target.files[0]);
                            setValue('name', watch('company_logo')?.name);
                          }}
                          hidden
                        />
                      </label>
                    </div>
                  )}
                />
              </div>
              <div className="w-full">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className="bg-grey-100"
                      {...field}
                      label=""
                      onWhitebg={true}
                      error={errors?.company_logo?.message}
                      disabled={!watch('company_logo')?.name}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="website_link"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required={true}
                label="Website Link"
                error={errors?.website_link?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <div className="w-full">
            <div className="w-full relative">
              <Controller
                name="facebook_link"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Facebook Link"
                    error={errors?.facebook_link?.message}
                    maxLength={50}
                    extraClasses="pl-16"
                    required={true}
                  />
                )}
              />
              <div className="text-green absolute bg-grey-200 xxl:left-[1.1px] xl:left-[1.1px] lg:left-[1px] md:left-[0.5px] sm:left-[1.1px] xs:left-[1.1px] xxl:top-[29px] xl:top-[25px] lg:top-[24.5px] md:top-[21px] sm:top-6 xs:top-6 flex justify-center items-center  w-[54px] rounded-l-md cursor-pointer  xxl:h-[54px] xl:h-[43px] lg:h-[43.5px] md:h-[39px] sm:h-[40px] xs:h-[40px]">
                <Facebook className="w-5" />
              </div>
            </div>
          </div>
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <div className="w-full">
            <div className="w-full relative">
              <Controller
                name="twitter_link"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Twitter Link"
                    error={errors?.twitter_link?.message}
                    maxLength={50}
                    extraClasses="pl-16"
                  />
                )}
              />
              <div className="text-green absolute bg-grey-200 xxl:left-[1.1px] xl:left-[1.1px] lg:left-[1px] md:left-[0.5px] sm:left-[1.1px] xs:left-[1.1px] xxl:top-[29px] xl:top-[25px] lg:top-[24.5px] md:top-[21px] sm:top-6 xs:top-6 flex justify-center items-center w-[54px] rounded-l-md cursor-pointer xxl:h-[54px] xl:h-[43px] lg:h-[43.5px] md:h-[39px] sm:h-[40px] xs:h-[40px]">
                <Twitter className="w-5 h-5" />
              </div>
            </div>
          </div>
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-3 sm:mt-3 xs:mt-4">
          <div className="w-full">
            <div className="w-full relative">
              <Controller
                name="instagram_link"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Instagram Link"
                    error={errors?.instagram_link?.message}
                    maxLength={50}
                    extraClasses="pl-16"
                  />
                )}
              />
              <div className="text-green absolute bg-grey-200 xxl:left-[1.1px] xl:left-[1.1px] lg:left-[1px] md:left-[0.5px] sm:left-[1.1px] xs:left-[1.1px] xxl:top-[29px] xl:top-[25px] lg:top-[24.5px] md:top-[21px] sm:top-6 xs:top-6 flex justify-center items-center w-[54px] rounded-l-md cursor-pointer xxl:h-[54px] xl:h-[43px] lg:h-[43.5px] md:h-[39px] sm:h-[40px] xs:h-[40px]">
                <Instagram className="w-5 h-5" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <Button
            type="submit"
            className=""
            disabled={updateCompanyLoad || logoUploadLoading || addCompanyLoad}
          >
            {updateCompanyLoad || logoUploadLoading || addCompanyLoad
              ? 'Please wait..'
              : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddCompanyModal;
