import { SuspenseLoader } from 'components';
import { useAuth } from 'hooks/useAuth';
import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const Access = ({ children, pathId = Infinity }) => {
  const { user, isUserLoading } = useAuth();
  const location = useLocation();

  const userPermissions = useMemo(() => user?.access_permissions || [],[user?.access_permissions]);
  if (isUserLoading) {
    return <SuspenseLoader />;
  }

  return userPermissions?.includes(pathId) ? (
    children
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export default Access;
