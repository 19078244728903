import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from 'components';
import SwitchInput from 'components/SwitchInput';
import * as yup from 'yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getRequiredText, navigationArr } from 'shared/resources';
import { useAddSubAdmin, useUpdateSubAdmin } from 'hooks';

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: '',
  access_permissions: [],
};
// const elementData = showModal?.elementData ?? {};

function AddSubAdmin({ handleClose = () => null, showModal = {} }) {
  const { elementData = {} } = showModal;
  const isEdit = elementData && Object.keys(elementData)?.length > 0;

  const AddvalidationSchema = yup.object().shape({
    first_name: yup.string().required(getRequiredText('First name')),
    email: yup.string().required(getRequiredText('Email')),
    password: yup.string().optional(),
    confirm_password: yup.string().optional(),
    access_permissions: yup
      .array()
      .min(1, `At least 1 ${getRequiredText('Access')}`),
  });

  const validationSchema = yup.object().shape({
    first_name: yup.string().required(getRequiredText('First name')),
    email: yup.string().required(getRequiredText('Email')),
    password: yup.string().required('Password is required'),
    confirm_password: yup
      .string()
      .required(' Confirm Password is required')
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
      }),

    access_permissions: yup
      .array()
      .min(1, `At least 1 ${getRequiredText('Access')}`),
  });

  const { mutate: addSubAdminMutate, isPending } = useAddSubAdmin({
    handleSuccess,
  });

  const { mutate: updateSubAdminMutate, isPending: updateSubAdminLoad } =
    useUpdateSubAdmin({
      handleSuccess,
    });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(isEdit ? AddvalidationSchema : validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (isEdit && elementData && Object.keys(elementData).length > 0) {
      setValue('first_name', elementData?.first_name);
      setValue('last_name', elementData?.last_name);
      setValue('email', elementData?.email);
      setValue('password', elementData?.password);
      setValue('confirmpassword', elementData?.confirm_password);
      setValue('access_permissions', elementData?.access_permissions || [], {
        shouldValidate: true,
      });
    }
  }, [isEdit]);

  const onSubmit = (values) => {
    const payload = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
      password: values?.password,
      access_permissions: values?.access_permissions,
    };
    if (isEdit) {
      updateSubAdminMutate({ uuid: elementData?.uuid, ...payload });
    } else {
      addSubAdminMutate(payload);
    }
  };

  function handleSuccess() {
    handleClose();
  }
  return (
    <div className="pl-10 pt-4 pr-8 overflow-y-auto max-h-screen w-[640px] p-5">
      <div className="text-xl font-semibold text-black mb-2">
        {isEdit ? 'Update Sub Admin' : 'Add Sub Admin'}
      </div>
      <form
        className="w--full d--flex flex--column gap--md"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-3 sm:mt-3 xs:mt-4">
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required={true}
                label="First Name"
                error={errors?.first_name?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-3 sm:mt-3 xs:mt-4">
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required={true}
                label="Last Name"
                error={errors?.last_name?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required={true}
                label="Email"
                error={errors?.email?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                maxLength="10"
                required={true}
                label="Password"
                error={errors?.password?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                maxLength="10"
                required={true}
                label="Confirm Password"
                error={errors?.confirm_password?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <label className="text-grey-700 text-base ">
            Access Permissions:<span className="text-danger">*</span>
          </label>
          <div className="w-full mt-6 grid grid-cols-2 gap-4">
            {navigationArr.map((access) => {
              if (access.id === 2) return;

              return (
                <div key={access.id} className="flex items-center">
                  <SwitchInput
                    id={access.id}
                    name={access.id}
                    label={access.label}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const newArray = watch('access_permissions');
                        newArray.push(access.id);
                        setValue('access_permissions', newArray, {
                          shouldValidate: true,
                        });
                      } else {
                        const newasd = watch('access_permissions')?.filter(
                          (item) => item !== access?.id,
                        );
                        setValue('access_permissions', newasd, {
                          shouldValidate: true,
                        });
                      }
                    }}
                    value={watch('access_permissions').includes(access?.id)}
                    checked={watch('access_permissions').includes(access?.id)}
                  />
                </div>
              );
            })}
            <div className="flex  flex-col">
              {errors?.access_permissions?.message ? (
                <small className="text-danger text-xs	font-normal mt-1">
                  {errors?.access_permissions?.message}
                </small>
              ) : null}
            </div>
          </div>
        </div>
        <div className="mt-6">
          {isEdit ? (
            <Button type="submit" className="" disabled={updateSubAdminLoad}>
              {isPending ? 'Please wait..' : 'Update'}
            </Button>
          ) : (
            <Button type="submit" className="" disabled={isPending}>
              {isPending ? 'Please wait..' : 'Submit'}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddSubAdmin;
