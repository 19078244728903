import { Button, ReadViewInput } from "components";
import React, { useEffect, useState, useMemo } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { DocumentUploadConstant } from "../constants";
import { Arrow, Check, Download } from "assets/images";
import { downloadFile } from "shared/resources";
import DocumentUploadInput from "components/DocumentUploadInput";
import { useKycVerification } from "hooks";
import { useQuery } from "@tanstack/react-query";
import { getKycDetails } from "api";
import KYCError from "./KYCError";
import KYCSuccess from "./KYCSuccess";

const PassportReadView = ({ values }) => {
  const watch = (key) => {
    if (key === "front_image" && typeof values?.front_image === "string") {
      return `${process.env.REACT_APP_BASE_API_URL}${
        values?.company_id || values?.uuid
      }/kyc/${values?.front_image}`;
    } else {
      return values?.[key];
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className=" w-full flex flex-col gap-4">
        <ReadViewInput
          labelText="Passport number"
          valueText={values?.passport_number}
        />
        <ReadViewInput labelText="First name" valueText={values?.first_name} />
      </div>
      <div className=" w-full flex flex-col gap-4">
        <ReadViewInput
          labelText="Middle name"
          valueText={values?.middle_name}
        />
        <ReadViewInput labelText="Surname" valueText={values?.last_name} />
      </div>
      <div className=" w-full flex flex-col gap-4 ">
        <ReadViewInput labelText="Date of birth" valueText={values?.dob} />
        <ReadViewInput labelText="Gender" valueText={values?.gender} />
      </div>
      <div className=" w-full flex flex-col gap-4 ">
        <ReadViewInput
          labelText="Issued date"
          valueText={values?.issued_date}
        />
        <ReadViewInput
          labelText="Expiry date"
          valueText={values?.expiry_date}
        />
      </div>
      <div className=" w-full flex flex-col gap-4 ">
        <ReadViewInput
          labelText="State of issue"
          valueText={values?.issue_state}
        />
        <DocumentUploadInput
          watch={watch}
          label="Upload front"
          valueKey="front_image"
          readView={true}
          showIIcon={false}
        />
      </div>
    </div>
  );
};

const NationalIdentityReadView = ({ values }) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <ReadViewInput
          labelText="NIN"
          valueText={values?.national_identity_number}
        />
      </div>
      <div>
        <ReadViewInput labelText="First name" valueText={values?.first_name} />
      </div>
      <div>
        <ReadViewInput
          labelText="Middle name"
          valueText={values?.middle_name}
        />
      </div>
      <div>
        <ReadViewInput labelText="Surname" valueText={values?.last_name} />
      </div>
      <div>
        <ReadViewInput labelText="Date of birth" valueText={values?.dob} />
      </div>
    </div>
  );
};

const DriversLicenseReadView = ({ values }) => {
  const watch = (key) => {
    if (key === "front_image" && typeof values?.front_image === "string") {
      return `${process.env.REACT_APP_BASE_API_URL}${
        values?.company_id || values?.uuid
      }/kyc/${values?.front_image}`;
    } else if (key === "back_image" && typeof values?.back_image === "string") {
      return `${process.env.REACT_APP_BASE_API_URL}${
        values?.company_id || values?.uuid
      }/kyc/${values?.back_image}`;
    } else {
      return values?.[key];
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex flex-col gap-4">
        <ReadViewInput
          labelText="License number"
          valueText={values?.licence_number}
        />
        <ReadViewInput labelText="First name" valueText={values?.first_name} />
      </div>
      <div className="w-full flex flex-col gap-4">
        <ReadViewInput
          labelText="Middle name"
          valueText={values?.middle_name}
        />
        <ReadViewInput labelText="Surname" valueText={values?.last_name} />
      </div>
      <div className="w-full flex flex-col gap-4 ">
        <ReadViewInput labelText="Date of birth" valueText={values?.dob} />
        <ReadViewInput labelText="Gender" valueText={values?.gender} />
      </div>
      <div className="w-full flex flex-col gap-4">
        <ReadViewInput
          labelText="Issued date"
          valueText={values?.issued_date}
        />
        <ReadViewInput
          labelText="Expiry date"
          valueText={values?.expiry_date}
        />
      </div>
      <div className="w-full flex flex-col gap-4 ">
        <ReadViewInput
          labelText="State of issue"
          valueText={values?.issue_state}
        />
      </div>

      <div className="w-full flex  gap-2  max-w-80">
        <DocumentUploadInput
          watch={watch}
          label="Upload front"
          valueKey="front_image"
          readView={true}
          showIIcon={false}
        />
        <DocumentUploadInput
          watch={watch}
          label="Upload back"
          valueKey="back_image"
          readView={true}
          showIIcon={false}
        />
      </div>
    </div>
  );
};

const VoterCardReadView = ({ values }) => {
  const watch = (key) => {
    if (key === "front_image" && typeof values?.front_image === "string") {
      return `${process.env.REACT_APP_BASE_API_URL}${
        values?.company_id || values?.uuid
      }/kyc/${values?.front_image}`;
    } else if (key === "back_image" && typeof values?.back_image === "string") {
      return `${process.env.REACT_APP_BASE_API_URL}${
        values?.company_id || values?.uuid
      }/kyc/${values?.back_image}`;
    } else {
      return values?.[key];
    }
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex flex-col gap-4">
        <ReadViewInput labelText="VIN" valueText={values?.voter_number} />
        <ReadViewInput labelText="First name" valueText={values?.first_name} />
      </div>
      <div className="w-full flex flex-col gap-4">
        <ReadViewInput
          labelText="Middle name"
          valueText={values?.middle_name}
        />
        <ReadViewInput labelText="Surname" valueText={values?.last_name} />
      </div>
      <div className="w-full flex flex-col gap-4 w-1/2 ">
        <ReadViewInput labelText="Date of birth" valueText={values?.dob} />
      </div>

      <div className="w-full flex gap-2  max-w-80">
        <DocumentUploadInput
          watch={watch}
          label="Upload front"
          valueKey="front_image"
          readView={true}
          showIIcon={false}
        />
        <DocumentUploadInput
          watch={watch}
          label="Upload back"
          valueKey="back_image"
          readView={true}
          showIIcon={false}
        />
      </div>
    </div>
  );
};

const BankVerificationReadView = ({ values }) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <ReadViewInput
          labelText="BVN"
          valueText={values?.bank_verification_number}
        />
      </div>
      <div>
        <ReadViewInput labelText="First name" valueText={values?.first_name} />
      </div>
      <div>
        <ReadViewInput
          labelText="Middle name"
          valueText={values?.middle_name}
        />
      </div>
      <div>
        <ReadViewInput labelText="Surname" valueText={values?.last_name} />
      </div>
      <div>
        <ReadViewInput labelText="Date of birth" valueText={values?.dob} />
      </div>
    </div>
  );
};

const KYCReadView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { kycId } = useParams();
  const [documentData, setDocumentData] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [selfieData, setSelfieData] = useState(null);
  const [isKYCInProgress, setIsKYCInProgress] = useState(true);

  const { mutate: kycVerificationMutate, isPending: kycVerificationLoad } =
    useKycVerification({ handleSuccess });

  const { data: state, refetch: refetchKyCDetails } = useQuery({
    queryKey: ["getKycDetails"],
    queryFn: () => getKycDetails({ uuid: kycId }),
    select: (data) => data?.data?.data,
    gcTime: 0,
  });

  useEffect(() => {
    if (searchParams) {
      const documentType = searchParams.get("documentType");
      setDocumentType(documentType);
    }
  }, [searchParams]);

  useEffect(() => {
    if (state && Object.keys(state).length > 0) {
      if (state?.kyc_details) {
        setDocumentType(state?.kyc_doc_type);
        setSelfieData({
          type: state?.identification_type,
          file: state?.identification_doc,
        });
        setIsKYCInProgress(true);
        setDocumentData({
          ...state?.kyc_details,
          status: state?.status,
          company_id: state?.company_uuid,
          uuid: state?.user_uuid,
        });
      }
    }
  }, [state]);

  const DocumentReadViewLookup = useMemo(() => {
    return {
      [DocumentUploadConstant.VOTER_CARD]: (
        <VoterCardReadView values={documentData} />
      ),
      [DocumentUploadConstant.BANK_VERIFICATION_NUMBER]: (
        <BankVerificationReadView values={documentData} />
      ),
      [DocumentUploadConstant.DRIVING_LICENSE]: (
        <DriversLicenseReadView values={documentData} />
      ),
      [DocumentUploadConstant.PASSPORT]: (
        <PassportReadView values={documentData} />
      ),
      [DocumentUploadConstant.NATIONAL_IDENTITY]: (
        <NationalIdentityReadView values={documentData} />
      ),
    };
  }, [documentData]);

  function handleSuccess() {
    refetchKyCDetails();
  }

  if (!kycId || kycId === undefined || kycId === "undefined") {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <div className="text-sm mt-5 font-semibold gap-2 py-3 bg-grey-100 text-grey rounded-full flex justify-center items-center w-full">
          No data found
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white w-full h-full overflow-auto px-6 pt-6 pb-6 flex justify-between">
      <div className="w-full pr-10">
        {state?.kyc_details ? (
          <div className="text-lg font-semibold mb-2">
            User submitted details/documents
          </div>
        ) : null}

        {isKYCInProgress ? null : (
          <div className="text-grey-700 text-sm mb-16">
            Review and confirm uploaded information
          </div>
        )}
        {isKYCInProgress && state?.kyc_details ? null : (
          <div className="flex items-center justify-center w-full h-full">
            <div className="text-sm mt-5 font-semibold gap-2 py-3 bg-grey-100 text-grey rounded-full flex justify-center items-center w-full">
              KYC is not submitted
            </div>
          </div>
        )}

        <div className="flex gap-6 w-full">
          {!isKYCInProgress ? (
            <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-white flex items-center justify-center bg-green">
              <Check />
            </div>
          ) : null}

          <div className="flex flex-col w-full max-w-[433px]">
            {!isKYCInProgress ? (
              <>
                <div className="text-grey-900 text-lg font-semibold">
                  Uploaded documents
                </div>
                <div className="text-grey-700 text-sm mb-8">
                  Uploaded relevant data information
                </div>
              </>
            ) : null}

            {DocumentReadViewLookup?.[documentType]}
            {selfieData ? (
              <>
                <div className="text-grey-900 text-lg font-semibold mt-16">
                  Others
                </div>
                <div className="text-grey-700 text-sm mb-8">
                  Captured selfie or uploaded identity document
                </div>
                <div>
                  <div className="font-medium text-base text-grey-800">
                    Upload a valid identification
                  </div>
                  <div className="flex">
                    {selfieData?.file &&
                    typeof selfieData?.file === "string" ? (
                      <img
                        className="rounded-md w-52 h-32"
                        src={`${process.env.REACT_APP_BASE_API_URL}${
                          documentData?.company_id || documentData?.uuid
                        }/kyc/${selfieData?.file}`}
                        alt="selfie"
                      />
                    ) : selfieData?.file ? (
                      <img
                        className="rounded-md w-52 h-32"
                        src={URL.createObjectURL(selfieData?.file)}
                        alt="selfie"
                      />
                    ) : null}

                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        downloadFile(
                          typeof selfieData?.file === "string"
                            ? `${process.env.REACT_APP_BASE_API_URL}${
                                documentData?.company_id || documentData?.uuid
                              }/kyc/${selfieData?.file}`
                            : selfieData?.file,
                          typeof selfieData?.file === "string"
                        )
                      }
                    >
                      <Download className="text-green" />
                    </div>
                  </div>

                  {!["COMPLETED", "IN_REVIEW", "REJECTED"].includes(
                    state?.status
                  ) ? (
                    <div className="w-64 mt-2">
                      <Button
                        type="button"
                        onClick={() =>
                          kycVerificationMutate({
                            uuid: state?.uuid,
                          })
                        }
                        disabled={kycVerificationLoad}
                      >
                        {kycVerificationLoad ? "Please wait..." : "Verify"}
                      </Button>
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!isKYCInProgress ? (
          <div className="mt-[100px]">
            <div className="flex gap-6">
              <div className="w-[80px]">
                <Button
                  extraClasses="mt-10"
                  type="button"
                  background="transparent"
                  onClick={() =>
                    navigate(`/KYC/customer-kyc?documentType=${documentType}`, {
                      state: documentData,
                    })
                  }
                >
                  <div className="text-black flex items-center">
                    <Arrow className="rotate-180 text-black" /> Back
                  </div>
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="border border-grey-400 h-full border-r"></div>
      <div className="flex w-full pl-10">
        <div className="w-full">
          <ResponseJson state={state} />
        </div>
      </div>
    </div>
  );
};

export default KYCReadView;

function ResponseJson({ state = {} }) {
  const hasData = state && Object.keys(state).length > 0;
  const kycData = {
    kyc_verification_response: state.kyc_verification_response,
    uuid: state?.uuid,
    kyc_verification_response_error: state?.kyc_verification_response_error,
    status: state?.status,
    rejection_reason: state?.rejection_reason,
  };
  const kycJson = {
    ...state.kyc_verification_response,
  };

  if (!hasData)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <div className="text-sm mt-5 font-semibold gap-2 py-3 bg-grey-100 text-grey rounded-full flex justify-center items-center w-full">
          No data found
        </div>
      </div>
    );

  return (
    <>
      {kycData?.kyc_verification_response_error &&
      kycData?.status !== "REJECTED" ? (
        <KYCError kycData={kycData} kycJson={kycJson} />
      ) : (
        <KYCSuccess kycData={kycData} kycJson={kycJson} />
      )}
    </>
  );
}
