import React, { useEffect, useRef } from 'react';

export default function Modal({
  children,
  handleClose,
  shouldCloseOnClickOutside = false,
  footerComponent: FooterComponent = () => null,
  headerComponent: HeaderComponent = () => null,
}) {
  const modalRef = useRef();

  useEffect(() => {
    if (modalRef.current) {
      const refrence = modalRef.current;
      refrence.showModal();

      refrence.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' || event.key === 'Esc') {
          event?.preventDefault();
          event = event || window?.event;
          let isEscape = false;
          if ('key' in event) {
            isEscape = event.key === 'Escape' || event.key === 'Esc';
          } else {
            isEscape = event.keyCode === 27;
          }
          if (isEscape) {
            closeModal();
          }
        }
      });

      if (shouldCloseOnClickOutside) {
        refrence.addEventListener(
          'click',
          (e) => {
            const modalDimesions = refrence.getBoundingClientRect();
            if (
              e.clientX < modalDimesions.left ||
              e.clientX > modalDimesions.right ||
              e.clientY < modalDimesions.top ||
              e.clientY > modalDimesions.bottom
            ) {
              closeModal();
            }
          },
          false,
        );
      }
    }
  }, [modalRef, closeModal, shouldCloseOnClickOutside]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function closeModal() {
    if (modalRef.current) {
      modalRef.current.removeEventListener('click', () => null, false);
      modalRef.current.removeEventListener('keydown', () => null, false);
      modalRef.current.close();
      handleClose();
    }
  }

  return (
    <dialog ref={modalRef} className="rounded-xl animate-modalAnimation ">
      {HeaderComponent && (
        <dialog-head>
          <div>Heading modal</div>
          <button type="button" onClick={() => closeModal()}>
            x
          </button>
        </dialog-head>
      )}

      <dialog-body>{children}</dialog-body>

      {FooterComponent && <dialog-footer>{FooterComponent}</dialog-footer>}
    </dialog>
  );
}
