import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toastEmitter } from '../../components/Toast';
import { deleteVirtualNumber } from 'api';

export default function useDeleteVirtualNumber({
  handleSuccess = () => null,
  handleError = () => null,
}) {
  const queryclient = useQueryClient();
  const Request = useMutation({
    mutationFn: deleteVirtualNumber,
    mutationKey: ['deleteVirtualNumber'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      toastEmitter('success', responseData?.data?.message);
      if (status !== 200) {
        handleError();
        return toastEmitter('error', responseData?.error?.message);
      }
      handleSuccess();
      queryclient.invalidateQueries(['getSubAdminList']);
    },
  });
  return { ...Request };
}
