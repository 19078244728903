import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deactivateSubAdmin } from '../../api';
import { toastEmitter } from 'components/Toast';

export default function useDeactivateSubAdmin({
  handleSuccess = () => null,
} = {}) {
  const queryclient = useQueryClient();

  const Request = useMutation({
    mutationFn: deactivateSubAdmin,
    mutationKey: 'deactivateSubAdmin',
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      toastEmitter('success', responseData?.data?.message);
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }
      queryclient.invalidateQueries(['getSubAdminList']);
      handleSuccess();
    },
  });
  return { ...Request };
}
