import { X } from 'assets/images';
import { Button, Input } from 'components';
import SwitchInput from 'components/SwitchInput';
import useUpdatePlan from 'hooks/useUpdatePlan';
import React, { useEffect, useState } from 'react';

const Item = ({
  item,
  bg,
  textCenter,
  isEdit,
  currentItemsArr = [],
  setCurrentItemsArr,
}) => {
  const ifTypeBoolean = item?.type === 'boolean';
  const [toggle, setToggle] = useState(false);
  const [itemValue, setItemValue] = useState('');

  useEffect(() => {
    if (item?.type === 'boolean') {
      setToggle(item?.value);
    } else {
      setItemValue(item?.value);
    }
  }, [item?.type]);

  function handleMutateArray(item, value) {
    currentItemsArr.map((val) => {
      if (val.key === item.key) {
        val.value = value;
      }
    });
    setCurrentItemsArr(currentItemsArr);
  }

  if (ifTypeBoolean) {
    return (
      <div
        className={`border-b border-grey-400 text-sm ${
          isEdit ? 'p-2' : 'p-3'
        } flex  ${textCenter ? 'justify-center' : 'justify-start'} `}
      >
        {isEdit ? (
          <SwitchInput
            id={item.key}
            name={item.key}
            label={''}
            onChange={(e) => {
              if (!e.target.checked) {
                setToggle(false);
                handleMutateArray(item, false);
              } else {
                setToggle(true);
                handleMutateArray(item, true);
              }
            }}
            value={toggle}
            // checked={toggle}
            // initialValue={toggle}
          />
        ) : item?.value ? (
          <X
            className={`w-4 ${
              bg === 'bg-green' ? 'text-white' : 'text-grey-900'
            }`}
          />
        ) : (
          <>&nbsp;</>
        )}
      </div>
    );
  }

  return (
    <div
      className={`border-b border-grey-400 text-sm ${
        isEdit ? 'p-2' : 'p-3'
      } flex  ${textCenter ? 'justify-center' : 'justify-start'} `}
    >
      {isEdit ? (
        <Input
          onChange={(e) => {
            setItemValue(e.target.value);
            handleMutateArray(item, e.target.value);
          }}
          name="itemValue"
          value={itemValue}
          height="h-[30px]"
        />
      ) : item?.value ? (
        <span
          className={`${bg === 'bg-green' ? 'text-white' : 'text-grey-900'}`}
        >
          {item?.value === '32767' ? 'Unlimited' : item?.value}
        </span>
      ) : (
        <>&nbsp;</>
      )}
    </div>
  );
};

const PricingItem = ({
  width = 'w-[30%]',
  title = 'Basic',
  headerBg = 'bg-white',
  bg = 'bg-white',
  highiLight = 'bg-black-600',
  itemsArr = [],
  textCenter = true,
  withActionBtn = true,
  plan = {},
}) => {
  const { mutate: updatePlanMutate, isPending: updatePlanLoad } = useUpdatePlan(
    {
      handleSuccess: () => {
        setIsEdit(false);
        window.location.reload();
      },
      handleError: () => {
        setIsEdit(false);
        window.location.reload();
      },
    },
  );
  const [isEdit, setIsEdit] = useState(false);
  const [currentItemsArr, setCurrentItemsArr] = useState([]);
  const [planName, setPlanName] = useState('');

  useEffect(() => {
    if (itemsArr && itemsArr.length > 0) {
      setCurrentItemsArr(itemsArr);
    }
  }, [itemsArr]);

  useEffect(() => {
    if (title) {
      setPlanName(title);
    }
  }, [title]);

  return (
    <div className={`border border-grey-400 rounded ${width} overflow-hidden`}>
      <div className={`${highiLight} h-1`}></div>
      <div
        className={`h-32 ${headerBg} ${
          bg === 'bg-green' ? 'text-white' : 'text-grey-900'
        }  text-2xl flex items-center justify-center border-b border-grey-400`}
      >
        {isEdit ? (
          <Input
            onChange={(e) => {
              setPlanName(e.target.value);
            }}
            name="itemValue"
            value={planName}
            height="h-[30px]"
          />
        ) : (
          <>{title}</>
        )}
      </div>
      <div className={`${bg}`}>
        {currentItemsArr?.map((item) => (
          <Item
            item={item}
            key={item?.key}
            bg={bg}
            textCenter={textCenter}
            isEdit={isEdit}
            currentItemsArr={currentItemsArr}
            setCurrentItemsArr={setCurrentItemsArr}
          />
        ))}
      </div>
      <div className={`${bg}  h-16 flex items-center justify-center`}>
        {withActionBtn ? (
          <>
            {!isEdit ? (
              <Button
                type="button"
                height="h-3/4"
                width="w-1/2"
                background={`${bg === 'bg-green' ? 'white' : 'green'}`}
                color={`${bg === 'bg-green' ? 'text-green' : 'text-white'}`}
                onClick={() => setIsEdit(true)}
              >
                Edit
              </Button>
            ) : null}
            {isEdit ? (
              <>
                <Button
                  type="button"
                  height="h-3/4"
                  width="w-1/2"
                  background={`${bg === 'bg-green' ? 'white' : 'green'}`}
                  color={`${bg === 'bg-green' ? 'text-green' : 'text-white'}`}
                  onClick={() =>
                    updatePlanMutate({
                      ...plan,
                      features: currentItemsArr,
                      plan_name: planName,
                    })
                  }
                  disabled={updatePlanLoad}
                >
                  Update
                </Button>
                <Button
                  type="button"
                  height="h-3/4"
                  width="w-1/2"
                  background={'danger'}
                  color={` text-white`}
                  onClick={() => {
                    setIsEdit(false);
                    window.location.reload();
                  }}
                  disabled={updatePlanLoad}
                >
                  Close
                </Button>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PricingItem;
