import { useQuery } from '@tanstack/react-query';
import { getAdminDetails } from 'api';
import { useAuth } from '../useAuth';

export default function useAdminDetails() {
  const { setUser, setIsUserLoading, user } = useAuth();
  const { isError, isSuccess, data, isLoading, isFetching, isPending } =
    useQuery({
      queryFn: getAdminDetails,
      queryKey: ['getAdminDetails'],
      enabled: !user,
    });

  if (isLoading || isFetching || isPending) {
    setIsUserLoading(true);
  }
  if (isError) {
    setUser(null);
  }

  if (isSuccess) {
    setUser(data?.data?.data);
  }
}
