import React, { useState } from 'react';
import { TableManager } from 'components';
import { getKycList } from 'api';
import { formatDateTime } from 'shared/resources';

const KYC = () => {
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Creation Date',
      cell: (props) => {
        const [date] = formatDateTime(props?.getValue());
        return <span>{date}</span>;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'kyc_details.first_name',
      header: () => 'Name',
      cell: (props) =>
        `${props?.row?.original?.kyc_details?.first_name} ${props?.row?.original?.kyc_details?.last_name}`,
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'country',
      header: () => 'Country',
      cell: (props) => `${props?.getValue() === 'NG' ? 'Nigeria' : ''} `,
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'kyc_details.bank_verification_number',
      header: () => 'Verification Number',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'rejection_reason',
      header: () => 'Rejection Reason',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      meta: {
        textAlign: 'left',
      },
    },
  ];

  const filterInitialValues = {
    filter: [
      {
        plan_name: '',
        a_filter_type: '',
        placeholder: 'Enter Plan',
        type: 'text',
        shouldFilterOptionDisabled: false,
        label: 'Plan',
        filterName: 'plan_name',
        position: 1,
      },
    ],
  };

  return (
    <>
      <div className="flex items-center justify-between mb-5"></div>
      <TableManager
        fetcherFn={getKycList}
        fetcherKey={'getKycList'}
        searchPlaceholder={'Search'}
        searchLabel=""
        isSearchable={true}
        shouldFilter={true}
        title="KYC"
        columns={columns}
        //   tableActions={tableActions}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
      />
    </>
  );
};

export default KYC;
