export const initialModalState = {
    modalType: '',
    isShow: false,
    elementData: {},
    type: '',
  };
  
  export const dropdownActions = {
    ADD_BUNDLE: 'add_bundle',
    DELETE_BUNDLE: 'delete_bundle',
    ACTIVATE_BUNDLE: 'activate_bundle',
    DEACTIVATE_BUNDLE: 'deactivate_bundle',
  };
  