import Skeletons from 'components/Skeletons';
import React from 'react';

const TableLoader = ({ columnLength, defaultRows = 10 }) => {
  return (
    <>
      {Array.from({ length: defaultRows }, (_, i) => i + 1).map((val) => {
        return (
          <tr key={`row${val}`}>
            {Array.from({ length: columnLength }, (_, i) => i + 1)?.map((v) => {
              return (
                <td
                  key={`col${v}`}
                  className="py-3 text-sm font-medium border-grey-400 border-b "
                >
                  <Skeletons />
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

export default TableLoader;
