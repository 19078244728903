import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addBundle } from '../../api';
import { toastEmitter } from 'components/Toast';

export default function useAddBundle({ handleSuccess = () => null } = {}) {
  const queryclient = useQueryClient();

  const Request = useMutation({
    mutationFn: addBundle,
    mutationKey: 'addBundle',
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }
      handleSuccess();
      queryclient.invalidateQueries(['getBundleList']);
      toastEmitter('success', responseData?.data?.message || "Bundle Added Succesfully");
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      toastEmitter({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
