export const dropdownActions = {
  ADD_VIRTUAL_NUMBER: 'add_virtual_number',
  DELETE_VIRTUAL_NUMBER: 'delete_virtual_number',
};
export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};
