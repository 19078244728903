import React from 'react';

export default function Tabs({
  tabList = [],
  currentTab = '',
  onWhitebg = false,
  height = 'xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]',
}) {
  return (
    <div
      className={`border group  p-1 ${height}  rounded-md flex items-center w-full justify-between gap-1   ${
        onWhitebg
          ? 'border-grey-400 bg-grey-300'
          : 'border-grey-500 text-grey-600'
      }`}
    >
      {tabList?.map(({ id, onclick: onClick, label, value }) => {
        return (
          <>
            <div
              key={id}
              onClick={onClick}
              className={`${
                value === currentTab
                  ? onWhitebg
                    ? 'bg-white text-grey-900 font-semibold rounded-md'
                    : 'bg-grey-900 text-white rounded-md'
                  : onWhitebg
                  ? 'text-grey-900 font-semibold rounded-md '
                  : 'bg-tranparent text-grey-600 '
              }  text-sm font-medium  h-full flex items-center justify-center   cursor-pointer w-full text-center   border-gray-400 `}
            >
              {label}
            </div>
            <div className="border-r border-grey-400 w-2 h-full  group-last-of-type:border-none last:w-0 last:border-r-0"></div>
          </>
        );
      })}
    </div>
  );
}
