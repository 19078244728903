import { useMutation } from '@tanstack/react-query';
import { kycVerification } from '../../api';
import { toastEmitter } from 'components/Toast';

export default function useKycVerification({
  handleSuccess = () => null,
} = {}) {
  const Request = useMutation({
    mutationFn: kycVerification,
    mutationKey: 'kycVerification',
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }

      handleSuccess();
    },
  });
  return { ...Request };
}
