import { Edit } from 'assets/images';
import { Avatar, Button, Input } from 'components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { ayt } from 'shared/resources';
// import { validationSchema } from '../constants';
import { useForm } from 'react-hook-form';

const initialvalues = {
  last_name: '',
  first_name: '',
  email: '',
  phone: '',
};
const Personal = () => {
  //   const imagePickerRef = useRef(null);
  //   const { user, setUser } = useAuth();
  //   const [profileImage, setProfileImage] = useState('');
  //   const { isPending, mutateAsync } = useProfileUpdate();
  //   const { isLoading: mediaUploadLoad, uploadMedia } = useUploadFile();
  //   const [showModal, setShowModal] = useState(initialModalState);
  const { control, handleSubmit } = useForm({
    // resolver: yupResolver(validationSchema),
    defaultValues: {
      initialvalues,
      //   first_name: user?.first_name ?? '',
      //   last_name: user?.last_name ?? '',
      //   email: user?.email ?? '',
      //   phone: user?.phone ? formatPhoneNumber(user?.phone) : '+234',
      //   profile_pic: user?.profile_pic ?? '',
    },
    mode: 'onChange',
  });

  //   async function handleUploadImage(imageData) {
  //     fetch(imageData)
  //       .then((res) => res.blob())
  //       .then(async (item) => {
  //         const profilePic = new File(
  //           [item],
  //           `profilepic.${item?.type?.split('/')?.[1] || 'png'}`,
  //         );
  //         const res = await uploadMedia({
  //           type: 'profile',
  //           file: profilePic,
  //         });
  //         mutateAsync({
  //           profile_pic: res?.file_name,
  //         }).then((response) => {
  //           if (response?.data?.success) {
  //             setUser({ ...user, profile_pic: res?.file_name });
  //           }
  //         });
  //         handleCloseImageCropper();
  //       });
  //   }

  //   const ModalLookup = {
  //     [modalTypes.PHONE_VERIFICATION]: (
  //       <CodeVerification
  //         handleClose={handleCloseModal}
  //         type="phone"
  //         isLoading={isPending}
  //         setValue={setValue}
  //         errors={errors}
  //         watch={watch}
  //         updateProfileFn={mutateAsync}
  //         updateProfileLoad={isPending}
  //       />
  //     ),
  //     [modalTypes.EMAIL_VERIFICATION]: (
  //       <CodeVerification
  //         handleClose={handleCloseModal}
  //         type="email"
  //         isLoading={isPending}
  //         setValue={setValue}
  //         errors={errors}
  //         watch={watch}
  //         updateProfileFn={mutateAsync}
  //         updateProfileLoad={isPending}
  //       />
  //     ),
  //   };

  //   function handleFileSelect(e) {
  //     let file = e.target.files[0];
  //     e.target.value = '';
  //     if (file && file?.type?.includes('image')) {
  //       try {
  //         const reader = new FileReader();
  //         reader.onload = () => {
  //           setProfileImage(reader.result);
  //         };
  //         reader.readAsDataURL(file);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     } else {
  //       toastEmitter('error', 'File type not supported.');
  //     }
  //   }

  function onSubmit(values) {
    console.log(values);
    // const payload = {
    //   first_name: values.first_name,
    //   last_name: values.last_name,
    // };

    // mutateAsync(payload);
  }

  //   function handleCloseModal() {
  //     setShowModal(initialModalState);
  //   }
  //   function handleCloseImageCropper() {
  //     setProfileImage(null);
  //   }

  //   const { isShow, modalType } = showModal;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-start xxl:w-9/12 xl:w-9/12 lg:w-10/12 md:w-10/12 sm:w-11/12 xs:w-11/12 m-auto mt-[70px] ">
        <div className="flex items-end">
          <Avatar
          // name={`${user?.first_name} ${user?.last_name}`}
          // mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
          //   user?.company_uuid || user?.uuid
          // }/profile/${user?.profile_pic}`}
          // size="106"
          />
          <div
            className="text-green cursor-pointer"
            // onClick={() => imagePickerRef?.current?.click()}
          >
            <Edit className="w-6 h-6 " />
            <Input
              //   ref={imagePickerRef}
              extraClasses="hidden"
              type="file"
              accept="image/png, image/jpeg"
              id="imagePickerRef"
              //   onChange={(e) => handleFileSelect(e)}
            />
          </div>
        </div>
        <div className="w-full">
          <div>
            <div className="flex gap-6 items-start mt-10">
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="First Name"
                    // error={errors?.first_name?.message}
                    maxLength={50}
                  />
                )}
              />

              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Last Name"
                    // error={errors?.last_name?.message}
                    maxLength={50}
                  />
                )}
              />
            </div>
            <div className="flex gap-6 mt-5">
              <div className="w-full flex justify-end items-center relative">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onWhitebg={true}
                      label="Email"
                      // error={errors?.email?.message}
                      maxLength={50}
                      helperText="This Email is used to log in to your account"
                      // disabled
                    />
                  )}
                />
                <div
                  className="text-green absolute  cursor-pointer"
                  //   onClick={() =>
                  //     setShowModal({
                  //       isShow: true,
                  //       modalType: modalTypes.EMAIL_VERIFICATION,
                  //     })
                  //   }
                >
                  <Edit className="w-6 h-6 mr-2" />
                </div>
              </div>
              <div className="w-full flex justify-end items-center relative">
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => {
                    const { onChange } = field;
                    return (
                      <Input
                        {...field}
                        onWhitebg={true}
                        label="Phone"
                        // error={errors?.phone?.message}
                        autoComplete="off"
                        maxLength="17"
                        helperText={<>&nbsp;</>}
                        onChange={(e) => {
                          const aytNumber = ayt(e.target.value);
                          onChange(aytNumber);
                        }}
                        // disabled
                      />
                    );
                  }}
                />
                <div
                  className="text-green absolute  cursor-pointer"
                  //   onClick={() =>
                  //     setShowModal({
                  //       isShow: true,
                  //       modalType: modalTypes.PHONE_VERIFICATION,
                  //     })
                  //   }
                >
                  <Edit className="w-6 h-6 mr-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex  items-center justify-center mt-[76px] mb-[1.7rem]">
        <Button
          width="w-[410px]"
          type="submit"
          border="border-green"
          //   disabled={isPending}
        >
          <div className="flex w-fit font-semibold  px-5 justify-center items-center text-white">
            {/* {isPending ? <Spinner /> : 'Save'} */}Save
          </div>
        </Button>
      </div>
      {/* {isShow ? ( */}
      {/* <Modal
        //   handleClose={handleCloseModal}
        headerComponent={null}
        footerComponent={null}
        shouldCloseOnClickOutside={false}
      > */}
      {/* {ModalLookup[modalType]} */}
      {/* </Modal> */}
      {/* ) : null} */}

      {/* {profileImage ? ( */}
      {/* <Modal
        //   handleClose={handleCloseImageCropper}
        headerComponent={null}
        footerComponent={null}
        shouldCloseOnClickOutside={false}
      > */}
      {/* <div className="w-[600px] xxl:h-[670px] lg:h-[660px] md:h-[650px] flex justify-center">
        <div className="w-[500px] h-[500px] mt-12">
          <ImageCropper
                {...{
                  image: profileImage,
                  setImage: setProfileImage,
                  upload: (data) => handleUploadImage(data),
                  mediaUploadLoad: mediaUploadLoad,
                }}
              />
        </div>
      </div> */}
      {/* </Modal> */}
      {/* ) : null} */}
    </form>
  );
};

export default Personal;
