import { Button } from 'components';
import { useUpdateKYC } from 'hooks';
import React, { useState } from 'react';

const KYCError = ({ kycData = {} }) => {
  const [isRejected, setIsRejected] = useState(false);
  const [reason, setRejectionReason] = useState('');
  const { mutate: updateKYCMutate, isPending: updateKYCLoad } = useUpdateKYC();

  function handleRejection(status) {
    if (!reason) return;
    updateKYCMutate({ status, rejection_reason: reason, uuid: kycData?.uuid });
  }

  return (
    <div className="flex items-center flex-col justify-center h-full">
      <div className="text-sm mt-5 font-semibold gap-2 py-3 bg-danger-100 text-danger rounded-full flex justify-center items-center w-full">
        <div>{JSON.parse(kycData?.kyc_verification_response_error) || kycData?.kyc_verification_response_error}</div>
      </div>

      <div className="w-full mt-2 flex gap-2">
        <Button
          background="danger"
          type="button"
          onClick={() => setIsRejected(true)}
        >
          Rejected
        </Button>
      </div>

      {isRejected ? (
        <>
          <div className="xxl:mt-5 xl:mt-5 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4 w-full">
            <label className="text-grey-800 text-base font-medium">
              Rejection Reason
            </label>
            <textarea
              className={`w-full border bg-transparent rounded-md focus:outline-none focus:ring focus:bg-white font-medium text-base text-grey-900  xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] ${
                reason
                  ? 'border-grey-400 focus:border-green focus:ring-green-200'
                  : 'border-danger focus:border-danger ring ring-danger-100 focus:ring-danger-100 bg-danger-100 focus:bg-danger-100'
              }`}
              placeholder="Write reason"
              value={reason}
              onChange={(e) => setRejectionReason(e?.target?.value)}
              id="rejection_reason"
              rows={6}
            ></textarea>
            <div className="w-full mt-2 flex gap-2">
              <Button
                background="danger"
                type="button"
                onClick={() => handleRejection('REJECTED')}
                disabled={updateKYCLoad}
              >
                {updateKYCLoad ? 'Please wait...' : 'Submit'}
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default KYCError;
