import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from 'components';
// import { useAuth } from 'hooks/useAuth';
import { useAddBundle } from 'hooks';
import SelectInput from 'components/SelectInput';

const initialValues = {
  country: 'NG',
  type: '',
  validity: '',
  total_bundles: '',
  cost: '',
};

const validationSchema = yup.object({
  country: yup.string().required('Country is required'),
  type: yup.string().required('Bundle type is required'),
  validity: yup.string().required('Bundle duration is required'),
  total_bundles: yup
    .number()
    .required(`Value is required`)
    .min(1, 'Must be greater than or equal to 1')
    .max(99999, 'It should be not more than 5 digits')
    .typeError(`Value field is required`),
  cost: yup
    .number()
    .required(`Value is required`)
    .min(1, 'Must be greater than or equal to 1')
    .max(99999, 'It should be not more than 5 digits')
    .typeError(`Value field is required`),
});

const AddBundleModal = ({ handleClose = () => null, showModal = {} }) => {
  const { elementData = {} } = showModal;
  const isEdit = elementData && Object.keys(elementData)?.length > 0;
  //   const { user } = useAuth();
  const { mutate: addBundleMutate, isPending: addBundleLoad } = useAddBundle({
    handleSuccess,
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (isEdit && elementData && Object.keys(elementData).length > 0) {
      setValue('country', elementData?.user?.country);
      setValue('type', elementData?.user?.type);
      setValue('validity', elementData?.user?.validity);
      setValue('total_bundles', elementData?.user?.total_bundles);
      setValue('cost', elementData?.user?.cost);
    }
  }, [isEdit]);

  const onSubmit = async (values) => {
    const payload = {
      country: values?.country,
      type: values?.type,
      validity: values?.validity,
      total_bundles: values?.total_bundles,
      cost: values?.cost,
    };
      addBundleMutate(payload);
  };

  function handleSuccess() {
    handleClose();
  }

  return (
    <div className="pl-10 pt-4 pr-8 overflow-y-auto max-h-screen w-[640px] p-5">
      <div className="text-xl font-semibold text-black mb-2">
        {isEdit ? 'Update Bundle' : 'Add Bundle'}
      </div>
      <form
        className="w--full d--flex flex--column gap--md"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-3 sm:mt-3 xs:mt-4">
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <SelectInput
                {...field}
                required={true}
                label="Country"
                placeholder="Select"
                options={[
                  {
                    label: 'Nigeria',
                    value: 'NG',
                  },
                ]}
                error={errors?.country?.message}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-3 sm:mt-3 xs:mt-4">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <SelectInput
                {...field}
                required={true}
                label="Bundle Type"
                placeholder="Select"
                options={[
                  {
                    label: 'Call',
                    value: 'CALL',
                  },
                  {
                    label: 'SMS',
                    value: 'SMS',
                  },
                ]}
                error={errors?.type?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="validity"
            control={control}
            render={({ field }) => (
              <SelectInput
                {...field}
                required={true}
                label="Bundle Duration"
                placeholder="Select"
                options={[
                  {
                    label: 'Weekly',
                    value: 'WEEKLY',
                  },
                  {
                    label: 'Monthly',
                    value: 'MONTHLY',
                  },
                  {
                    label: 'Yearly',
                    value: 'YEARLY',
                  },
                ]}
                error={errors?.validity?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="total_bundles"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                label="Total Bundles"
                maxLength="10"
                required={true}
                placeholder={`${
                  watch('type') === 'CALL'
                    ? 'Enter minutes'
                    : watch('type') === 'SMS'
                    ? 'Enter sms'
                    : ''
                }`}
                error={errors?.total_bundles?.message}
              />
            )}
          />
        </div>
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="cost"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                label="Cost"
                maxLength="10"
                required={true}
                placeholder="Enter cost"
                error={errors?.cost?.message}
              />
            )}
          />
        </div>
        <div className="mt-6">
          <Button
            type="submit"
            className=""
            disabled={addBundleLoad}
          >
            {addBundleLoad ? 'Please wait..' : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddBundleModal;
