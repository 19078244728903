import { X } from 'assets/images';
import Button from 'components/Button';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const filterTypes = [
  { label: 'Begins With', value: 'begin_with' },
  { label: 'Contains', value: 'contains' },
  { label: "Doesn't contains", value: 'does_not_contains' },
  { label: 'Is Equal To', value: 'is_equal' },
  { label: 'Is Not Equal To', value: 'is_not_equal' },
  { label: 'Ends With', value: 'ends_with' },
];

const GlobalFilters = ({
  setFilters,
  initialValues,
  filters,
  isSearching = false,
  setOpenFilter,
  openFilter,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSubmitForm = async (values) => {
    const { filter } = values;
    const filteredArray = filter?.filter(
      (value) =>
        value[Object.keys(value)[0]] || value[Object.keys(value)[0]] === 0,
    );

    const isDateFilterExist = filteredArray?.filter((f) => f?.type === 'date');

    if (isDateFilterExist && isDateFilterExist.length > 0) {
      if (
        (isDateFilterExist?.[0]?.date?.from === '' ||
          isDateFilterExist?.[0]?.date?.to === '') &&
        isDateFilterExist?.[0]?.a_filter_type === ''
      ) {
        const filtersTosend = filteredArray?.filter((f) => f?.type !== 'date');
        setFilters(filtersTosend);
      } else {
        setFilters(filteredArray);
      }
    } else {
      setFilters(filteredArray);
    }

    setOpenFilter(!openFilter);
  };
  const { register, handleSubmit, reset, setValue, getValues, watch } = useForm(
    {
      mode: 'onSubmit',
      defaultValues: initialValues,
    },
  );
  const DATE_FILTER_INDEX = getValues()?.filter?.findIndex(
    (value) => Object.keys(value)[0] === 'date',
  );

  const watchDateFilter = watch(`filter[${DATE_FILTER_INDEX}].a_filter_type`);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.from`,
      moment(start).format('YYYY-MM-DD'),
    );
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.to`,
      moment(end).format('YYYY-MM-DD'),
    );
  };
  const onChangeSingleDate = (date) => {
    setStartDate(date);
    setEndDate(date);
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.from`,
      moment(date).format('YYYY-MM-DD'),
    );
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.to`,
      moment(date).format('YYYY-MM-DD'),
    );
  };

  const setToday = () => {
    if (startDate || endDate) {
      setStartDate(null);
      setEndDate(null);
    }
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.from`,
      moment().format('YYYY-MM-DD'),
    );
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.to`,
      moment().format('YYYY-MM-DD'),
    );
  };
  const setYesterday = () => {
    if (startDate || endDate) {
      setStartDate(null);
      setEndDate(null);
    }
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.from`,
      moment().subtract(1, 'days').format('YYYY-MM-DD'),
    );
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.to`,
      moment().subtract(1, 'days').format('YYYY-MM-DD'),
    );
  };
  const setSevenDays = () => {
    if (startDate || endDate) {
      setStartDate(null);
      setEndDate(null);
    }
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.from`,
      moment().subtract(6, 'days').format('YYYY-MM-DD'),
    );
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.to`,
      moment().format('YYYY-MM-DD'),
    );
  };
  const setThirtyDays = () => {
    if (startDate || endDate) {
      setStartDate(null);
      setEndDate(null);
    }
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.from`,
      moment().subtract(29, 'days').format('YYYY-MM-DD'),
    );
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.to`,
      moment().format('YYYY-MM-DD'),
    );
  };
  const setThisMonth = () => {
    if (startDate || endDate) {
      setStartDate(null);
      setEndDate(null);
    }
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.from`,
      moment().startOf('month').format('YYYY-MM-DD'),
    );
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.to`,
      moment().endOf('month').format('YYYY-MM-DD'),
    );
  };
  const setLastMonth = () => {
    if (startDate || endDate) {
      setStartDate(null);
      setEndDate(null);
    }
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.from`,
      moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    );
    setValue(
      `filter[${DATE_FILTER_INDEX}].date.to`,
      moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    );
  };
  const setDefault = () => {
    if (startDate || endDate) {
      setStartDate(null);
      setEndDate(null);
    }
    setValue(`filter[${DATE_FILTER_INDEX}].date.from`, '');
    setValue(`filter[${DATE_FILTER_INDEX}].date.to`, '');
  };
  const handleChangeDateFilterType = (type) => {
    switch (type) {
      case 'Today':
        return setToday();
      case 'Yesterday':
        return setYesterday();
      case 'Last 7 Days':
        return setSevenDays();
      case 'Last 30 Days':
        return setThirtyDays();
      case 'This Month':
        return setThisMonth();
      case 'Last Month':
        return setLastMonth();
      default:
        return setDefault();
    }
  };
  useEffect(() => {
    if (filters && filters.length > 0) {
      const one = filters.map((filter) => filter?.filterName) || [];
      const two = initialValues?.filter?.filter(
        (filter) => !one.includes(filter?.filterName),
      );
      const newInitialValues = [...two, ...filters];

      setValue(
        'filter',
        newInitialValues.sort((a, b) => a.position - b.position),
      );
    }
  }, []);
  useEffect(() => {
    if (DATE_FILTER_INDEX === -1) {
      return;
    } else {
      if (getValues()?.filter?.[DATE_FILTER_INDEX]?.a_filter_type) {
        handleChangeDateFilterType(
          getValues()?.filter?.[DATE_FILTER_INDEX]?.a_filter_type,
        );
      }
    }
  }, [watchDateFilter]);

  const handleClearFilter = () => {
    reset();
    setStartDate(null);
    setEndDate(null);
    setFilters([]);
  };
  const handleClearParticularFilter = (filterToRemove) => {
    const requiredIndex = getValues()?.filter?.findIndex(
      (value) => Object.keys(value)[0] === Object.keys(filterToRemove)[0],
    );
    if (requiredIndex === -1) {
      return false;
    }

    if (Object.keys(filterToRemove)[0] === 'date') {
      setValue(`filter[${requiredIndex}].a_filter_type`, '');
      setValue(`filter[${requiredIndex}].date.from`, '');
      setValue(`filter[${requiredIndex}].date.to`, '');
    } else {
      setValue(`filter[${requiredIndex}].a_filter_type`, '');
      setValue(
        `filter[${requiredIndex}][${Object.keys(filterToRemove)[0]}]`,
        '',
      );
    }
    handleSubmit(handleSubmitForm)();
  };

  const prepareFilterOptions = (index, filterProps) => {
    const { filterOptions, shouldFilterOptionDisabled } = filterProps;
    if (filterOptions && filterOptions.length > 0) {
      return (
        <select
          {...register(`filter[${index}].a_filter_type`)}
          disabled={shouldFilterOptionDisabled}
        >
          <option value="">Filter type</option>
          {filterOptions.map(({ label, value }, index) => (
            <option value={value} key={index}>
              {label}
            </option>
          ))}
        </select>
      );
    }

    return (
      <select
        {...register(`filter[${index}].a_filter_type`)}
        disabled={shouldFilterOptionDisabled}
      >
        <option value="">Filter type</option>
        {filterTypes.map(({ label, value }, index) => (
          <option value={value} key={index}>
            {label}
          </option>
        ))}
      </select>
    );
  };

  const prepareFilterInput = (index, filterProps, filterKey) => {
    const { type, selectOptions, placeholder } = filterProps;
    if (type === 'select') {
      if (selectOptions && selectOptions.length > 0) {
        return (
          <select
            {...register(`filter[${index}].${filterKey}`)}
            placeholder={`Enter ${filterKey}...`}
          >
            <option value="">{placeholder}</option>
            {selectOptions.map((option, index) => (
              <option value={option.value || option.isoCode} key={index}>
                {option.label || option.name}
              </option>
            ))}
          </select>
        );
      }
    }

    if (type === 'text') {
      return (
        <input
          type="text"
          {...register(`filter[${index}].${filterKey}`)}
          placeholder={placeholder}
        />
      );
    }
    if (type === 'number') {
      return (
        <input
          {...register(`filter[${index}].${filterKey}`)}
          type="number"
          step="any"
          placeholder={placeholder}
        />
      );
    }

    if (type === 'date') {
      if (getValues()?.filter?.[index]?.a_filter_type === 'Select Date') {
        const fromDate = watch(`filter[${index}].date.from`);
        return (
          <>
            <input
              type="date"
              name="normal_date"
              id="normal_date"
              placeholder="Select or type a date..."
              value={fromDate}
              className="form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200 w--full"
              onChange={(e) => {
                onChangeSingleDate(e.target.value);
              }}
              disabled={
                getValues()?.filter?.[index]?.a_filter_type !== 'Select Date'
              }
            />
          </>
        );
      } else {
        const fromDate = watch(`filter[${index}].date.from`);
        const toDate = watch(`filter[${index}].date.to`);
        return (
          <span className="d--flex gap--md">
            <input
              className={`form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200 ${
                getValues()?.filter?.[index]?.a_filter_type !== 'Custom'
                  ? 'c--not-allowed'
                  : ''
              }`}
              type="date"
              name="startDate"
              id="startDate"
              placeholder={placeholder}
              value={fromDate}
              onChange={(e) => {
                onChange([
                  e.target.value,
                  getValues()?.filter?.[index]?.date?.to,
                ]);
              }}
              disabled={
                getValues()?.filter?.[index]?.a_filter_type !== 'Custom'
              }
            />
            <input
              // className={`form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200 ${
              //   getValues()?.filter?.[index]?.a_filter_type !== 'Custom'
              //     ? 'c--not-allowed'
              //     : ''
              // }`}
              type="date"
              name="endDate"
              id="endDate"
              value={toDate}
              placeholder={placeholder}
              onChange={(e) => {
                onChange([
                  getValues()?.filter?.[index]?.date?.from,
                  e.target.value,
                ]);
              }}
              disabled={
                getValues()?.filter?.[index]?.a_filter_type !== 'Custom'
              }
            />
          </span>
        );
      }
    }
    if (type === 'date-input') {
      return (
        <input
          {...register(`filter[${index}].${filterKey}`)}
          type="date"
          // step="any"

          placeholder={placeholder}
        />
      );
    }
  };
  return (
    <form
      className="w-full h-full overflow-y-auto px-5"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className="overflow-auto flex flex-col gap-2 p-2">
        {filters && filters.length > 0 ? (
          <>
            <div className=" flex justify-between">
              <div className="flex gap-1  overflow-auto">
                {filters.map((filter) => (
                  <>
                    <span className=" ">
                      {filter?.label ?? ''}

                      <button
                        type="button"
                        className=""
                        onClick={() => handleClearParticularFilter(filter)}
                      >
                        <X />
                      </button>
                    </span>
                  </>
                ))}
              </div>
              <span className="flex items-center ">
                <small className="flex items-center">
                  <b>Clear {filters.length} filter(s) </b>

                  <button
                    type="button"
                    className=""
                    onClick={() => handleClearFilter()}
                  >
                    <X />
                  </button>
                </small>
              </span>
            </div>
          </>
        ) : null}

        <div className="flex flex-col w-full">
          {initialValues &&
            initialValues.filter &&
            initialValues.filter.length > 0 &&
            initialValues.filter.map((filter, index) => {
              const isFilterDate = filter?.type === 'date';
              return (
                <div className="flex items-center" key={filter?.label}>
                  {isFilterDate ? (
                    <div className="flex flex-col">
                      {prepareFilterOptions(index, filter)}
                      {prepareFilterInput(
                        index,
                        filter,
                        Object.keys(filter)?.[0],
                      )}
                    </div>
                  ) : (
                    <div className="flex w-full">
                      {prepareFilterInput(
                        index,
                        filter,
                        Object.keys(filter)?.[0],
                      )}
                      {prepareFilterOptions(index, filter)}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex justify-between gap-2 ">
        <Button type="button" onClick={() => setOpenFilter(!openFilter)}>
          Cancel
        </Button>

        <Button type="submit" disabled={isSearching}>
          Search
        </Button>
      </div>
    </form>
  );
};

export default GlobalFilters;
