import React, { useState, useContext, createContext, useMemo } from 'react';
import { getToken } from 'shared/resources';
const AuthContext = createContext(null);

export const Auth = ({ children }) => {
  const [user, setCurrentUser] = useState(null);
  const [token, setToken] = useState(getToken());
  const [isUserLoading, setIsUserLoading] = useState(true);

  const setUser = (payload) => {
    setCurrentUser(payload);
    setIsUserLoading(false);
  };

  const setUserToken = (token) => {
    setToken(token);
    localStorage.setItem('fonu-acc-admin-tkn', token);
    window.location.reload(true);
  };

  const clearUser = () => {
    localStorage.clear();
    setCurrentUser(null);
    setToken(null);
  };

  const values = useMemo(() => ({
    user,token,setUser,clearUser,isUserLoading,setIsUserLoading,setUserToken,
  }),
    [user],
  );
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
