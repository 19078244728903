import React from 'react';
import { TableManager } from 'components';
import { getKycPaymentsList } from 'api';
import { colorLookup, formatDateTime } from 'shared/resources';
import { useParams } from 'react-router-dom';

function Payments() {
  const { id: company_uuid } = useParams();

  const columns = [
   
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: (props) => {
        const [date] = formatDateTime(props.getValue());
        return date;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'bill_no',
      header: () => 'transaction No',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'type',
      header: () => 'Type',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'desc',
      header: () => 'Description',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'amount',
      header: () => 'Amount',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        return (
          <div
            className={`capitalize ${
              colorLookup[props?.getValue()?.toLowerCase()]
            }`}
          >
            {props?.getValue()?.toLowerCase()?.split('_')?.join(' ')}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between mb-5"></div>

      <TableManager
        fetcherFn={getKycPaymentsList}
        fetcherKey={'getKycPaymentsList'}
        // searchPlaceholder={'Search'}
        // searchLabel=""
        // isSearchable={true}
        // shouldFilter={true}
        title="Payments"
        extraParams={{ company_uuid }}
        columns={columns}
      />
    </>
  );
}

export default Payments;
