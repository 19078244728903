import moment from 'moment';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';

export const getRequiredText = (field) => {
  return `${field} is required.`;
};

export function getToken() {
  return localStorage.getItem('fonu-acc-admin-tkn') || null;
}

export const passwordRegx =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const ayt = (number) => {
  return new AsYouType().input(number);
};

export const stringToColour = (str) => {
  if (!str || !str.length) return;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const lightenColor = (hexColor, factor = 20) => {
  if (!hexColor) return '#fff';

  // Remove the '#' character if present
  hexColor = hexColor.replace('#', '');

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Lighten the color by increasing the RGB values
  const newR = Math.min(255, r + factor);
  const newG = Math.min(255, g + factor);
  const newB = Math.min(255, b + factor);

  // Convert the lightened RGB back to hex
  const lightenedColor = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return lightenedColor;
};

export const darkenColor = (hexColor, factor = 20) => {
  if (!hexColor) return '#fff';
  // Remove the '#' character if present
  hexColor = hexColor.replace('#', '');

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Darken the color by reducing the RGB values
  const newR = Math.max(0, r - factor);
  const newG = Math.max(0, g - factor);
  const newB = Math.max(0, b - factor);

  // Convert the darkened RGB back to hex
  const darkenedColor = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return darkenedColor;
};

export const formatPhoneNumber = (number) => {
  if (!number) return;
  const phoneNumber = parsePhoneNumber(`${number}`);
  return phoneNumber?.formatInternational();
};

export const formatDateTime = (timestring, utc = true) => {
  if (!timestring) return '---';
  if (utc) {
    const utcMoment = moment.utc(timestring);
    const localMoment = utcMoment.local();
    let timeStrings = localMoment?.format();
    if (!timeStrings) return '---';
    return moment(timeStrings).format('DD MMM, YYYY&hh:mm A')?.split('&');
  }
  return moment(timestring).format('DD MMM, YYYY&hh:mm A')?.split('&');
};
export const NG_CURRENCY_SYMBOL = '₦';
export const IdPathMap = {
  1: 'Dashboard',
  2: 'Sub Admin',
  3: 'Companies',
  4: 'Virtual Number',
  5: 'Transaction',
  6: 'Calls',
  7: 'SMS',
  8: 'Plan',
  9: 'Settings',
};

export const navigationArr = [
  {
    id: 1,
    path: '#',
    parent: 'dashboard',
    label: 'Dashboard',
  },
  {
    id: 2,
    path: '/sub-admin',
    parent: 'sub-admin',
    label: 'Sub Admin',
  },
  {
    id: 3,
    path: '/companies',
    parent: 'companies',
    label: 'Company',
  },

  {
    id: 4,
    path: '/virtual-numbers',
    parent: 'virtual-numbers',
    label: 'Virtual Number',
  },

  {
    id: 5,
    path: 'transaction',
    parent: 'transaction',
    label: 'Transaction',
  },
  {
    id: 6,
    path: '#',
    parent: 'calls',
    label: 'Calls',
  },
  {
    id: 7,
    path: '#',
    parent: 'sms',
    label: 'SMS',
  },
  {
    id: 8,
    path: 'plan',
    parent: 'plan',
    label: 'Plan',
  },
  {
    id: 9,
    path: '#',
    parent: 'settings',
    label: 'Settings',
  },
  {
    id: 10,
    path: 'bundle',
    parent: 'bundle',
    label: 'Bundle',
  },
];

export const downloadFile = (file, isUrl = false) => {
  if (!file) return;
  const element = document.createElement('a');
  element.setAttribute('href', isUrl ? file : URL.createObjectURL(file));
  element.setAttribute('download', isUrl ? 'voicemail' : file?.name ?? '');
  element.setAttribute('target', '_blank');
  element.click();
};

export const colorLookup = {
  pending: 'text-[#E29704]',
  submitted: 'text-[#3333FF]',
  in_review: 'text-[#87909B]',
  completed: 'text-[#4F772D]',
  rejected: 'text-[#E81313]',
};

export function base64toFile(base64Data, fileName = 'selfie.png') {
  if (!base64Data) return;
  // Split the base64 string to get the content type and the actual base64 content
  const base64Parts = base64Data.split(';base64,');
  const contentType = base64Parts[0].split(':')[1];
  const raw = window.atob(base64Parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([uInt8Array], { type: contentType });

  // Create a File from the Blob
  const file = new File([blob], fileName, { type: contentType });

  return file;
}

export const rolesArray = [
  { id: 2, label: 'Admin', value: 4 },
  { id: 3, label: 'Owner', value: 2 },
  { id: 1, label: 'Member', value: 3 },
];
