import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addVirtualNumber } from 'api';
import { toastEmitter } from 'components/Toast';
import { useNavigate } from 'react-router-dom';

export default function useIVRMutate({ handleSuccess = () => null } = {}) {
  const queryclient = useQueryClient();
  const navigate = useNavigate();

  const Request = useMutation({
    mutationFn: addVirtualNumber,
    mutationKey: 'addVirtualNumber',
    onSuccess: (data) => {
      const status = data?.status;
      navigate('/virtual-numbers');
      const responseData = data?.data;
      if (status === 422) {
        handleSuccess();
        return toastEmitter('error', responseData?.error?.message);
      }
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }
      handleSuccess();
      queryclient.invalidateQueries(['getCompanyList']);
      toastEmitter('success', responseData?.data?.message);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      toastEmitter({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
