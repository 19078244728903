import React, { useMemo, useState } from 'react';
import { AsideModal, Button, Dropdown, Modal } from 'components';
import TableManager from 'components/TableManager';
import { getVirtualNumbers } from 'api';

import AddVirtualNumber from './AddVirtualNumber';
import { Edit, MenuIcon, Trash } from 'assets/images';
import useDeleteVirtualNumber from 'hooks/useDeleteVirtualNumber';
import DeleteVirtualNumberModal from './AddVirtualNumber/DeleteVirtualNumberModal';
import {
  dropdownActions,
  initialModalState,
} from './AddVirtualNumber/constants';
import { formatDateTime } from 'shared/resources';

function VirtualNumber() {
  const [filters, setFilters] = useState([]);
  const { mutate: deleteVirtualNumber, isPending: deletevirtualNumberLoad } =
    useDeleteVirtualNumber({ handleSuccess: handleDeleteSuccess });

  function handleDeleteSuccess() {
    handleCloseModal();
  }
  const [openFilter, setOpenFilter] = useState(false);
  const [showModal, setShowModal] = useState(initialModalState);
  const { isShow, modalType, type } = showModal;

  const columns = useMemo(
    () => [
      {
        accessorKey: 'created_at',
        header: () => 'Creation Date',
        cell: (props) => {
          const [date] = formatDateTime(props?.getValue());
          return <span>{date}</span>;
        },
      },
      {
        accessorKey: 'did_number',
        header: () => 'Number',
      },
      {
        accessorKey: 'did_city',
        header: () => 'City',
      },
      {
        accessorKey: 'country',
        header: () => 'Country',
      },
      {
        accessorKey: 'monthly_cost',
        header: () => 'Monthly Cost',
      },
      {
        accessorKey: 'did_type',
        header: () => 'Type',
        cell: (props) => {
          return props?.getValue() === 'N'
            ? 'National'
            : props?.getValue() === 'L'
            ? 'Local'
            : 'Toll Free';
        },
      },

      {
        accessorKey: 'action',
        header: () => 'Action',
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <div className="flex gap-2 items-center">
              <Dropdown
                menuWidth="w-max"
                maxHeight={false}
                closeOnClickOutside={true}
                dropList={{
                  component: ({ item }) => {
                    const Icon = item?.icon ?? null;
                    const del = item?.type === 'del' ? 'text-danger' : '';
                    if (item.access) {
                      return (
                        <span
                          className={`w-full h-full flex justify-start gap-2 items-center py-2 px-3 ${del}`}
                          onClick={() => handleDropListClick(item, element)}
                        >
                          {Icon} Virtual Number
                        </span>
                      );
                    }
                  },
                  data: [
                    {
                      id: 1,
                      title: 'Edit Virtual Number',
                      value: dropdownActions.ADD_VIRTUAL_NUMBER,
                      icon: <Edit className="text-grey-700" />,
                      access: true,
                    },

                    {
                      id: 2,
                      title: 'Delete VIrtual Number',
                      value: dropdownActions.DELETE_VIRTUAL_NUMBER,
                      icon: <Trash className=" text-danger " />,
                      access: true,
                      type: 'del',
                    },
                  ],
                }}
                showcaret={false}
              >
                <div className="cursor-pointer w-1">
                  <MenuIcon />
                </div>
              </Dropdown>
            </div>
          );
        },

        meta: {
          colType: 'action',
          width: '5%',
          hasAccess: true,
        },
      },
    ],
    [],
  );

  const tableActions = [
    {
      id: 1,
      label: 'Add Virtual Number +',
      access: true,
      component: (
        <div className=" w-64">
          <Button
            type="button"
            height="h-10"
            onClick={() =>
              setShowModal({
                isShow: true,
                modalType: dropdownActions.ADD_VIRTUAL_NUMBER,
                elementData: {},
              })
            }
          >
            Add Virtual Number
          </Button>
        </div>
      ),
    },
  ];

  const handleDropListClick = ({ value = '' }, element = {}) => {
    switch (value) {
      case dropdownActions.ADD_VIRTUAL_NUMBER:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'side',
        });
        break;
      case dropdownActions.DELETE_VIRTUAL_NUMBER:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'center',
        });
        break;

      default:
        break;
    }
  };

  const filterInitialValues = {
    filter: [
      {
        account: '',
        a_filter_type: '',
        placeholder: 'Enter account',
        type: 'text',
        shouldFilterOptionDisabled: false,
        label: 'Account',
        filterName: 'account',
        position: 1,
      },
    ],
  };

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  const ModalLookup = {
    [dropdownActions.ADD_VIRTUAL_NUMBER]: (
      <AddVirtualNumber
        handleClose={() => setShowModal(initialModalState)}
        showModal={showModal}
      />
    ),
    [dropdownActions.DELETE_VIRTUAL_NUMBER]: (
      <DeleteVirtualNumberModal
        showModal={showModal}
        handleClose={handleCloseModal}
        deleteFn={deleteVirtualNumber}
        isLoading={deletevirtualNumberLoad}
      />
    ),
  };

  return (
    <>
      <div className="flex items-center justify-between mb-5"></div>

      <TableManager
        fetcherFn={getVirtualNumbers}
        fetcherKey={'getVirtualNumbers'}
        // searchPlaceholder={'Search by number, name, or members'}
        // searchLabel=""
        // isSearchable={true}
        // shouldFilter={true}
        columns={columns}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        tableActions={tableActions}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
      />
      {isShow && (
        <>
          {type === 'center' ? (
            <Modal
              handleClose={handleCloseModal}
              headerComponent={null}
              footerComponent={null}
              shouldCloseOnClickOutside={false}
            >
              {ModalLookup[modalType]}
            </Modal>
          ) : (
            <AsideModal
              handleClose={() => setShowModal(initialModalState)}
              footerComponent={null}
              headerComponent={null}
              shouldCloseOnClickOutside={true}
            >
              {ModalLookup[modalType]}
            </AsideModal>
          )}
        </>
      )}
    </>
  );
}

export default VirtualNumber;
