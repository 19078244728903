import React from 'react';

export default function Skeletons({ type = 'bar', height = 'h-5' }) {
  if (type === 'circle') {
    return (
      <div className="animate-pulse flex space-x-4 h-full w-full">
        <div className="rounded-full bg-grey-600 h-full w-full"></div>
      </div>
    );
  }

  return (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-6 py-1">
        <div className={`${height} bg-grey-600 rounded-md`}></div>
      </div>
    </div>
  );
}
