import React from 'react';

const ReadViewInput = ({
  labelText = '',
  valueText = '',
  labelClasses = '',
  valueClasses = 'font-semibold text-base text-grey-900',
}) => {
  return (
    <div className="flex  gap-2 w-full">
      <div className="flex justify-between items-center">
        {labelText ? (
          <label
            htmlFor={labelText}
            className={`text-grey-800 min-w-150 xxl:text-base xl:text-sm lg:text-sm md:text-xs font-medium ${labelClasses} capitalize`}
          >
            {labelText?.toLowerCase()?.split('_')?.join(' ')}
          </label>
        ) : null}
      </div>

      <span className={`w-full ${valueClasses}`}>{valueText || '---'}</span>
    </div>
  );
};

export default ReadViewInput;
