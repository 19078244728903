import { Chevron } from 'assets/images';
import Spinner from 'components/Spinner';
import { useClickOutside } from 'hooks';
import React, { useLayoutEffect, useRef, useState } from 'react';

const Dropdown = ({
  children,
  dropList = {},
  closeOnClickOutside = false,
  caretComponent: CaretComponent = Chevron,
  showcaret = false,
  header: Header = null,
  footer: Footer = null,
  menuWidth = 'w-full',
  isLoading = false,
  maxHeight = true,
}) => {
  const [show, setShow] = useState(false);
  const [shouldSetStyle, setShouldSetStyle] = useState(false);
  const elRef = useRef(null);
  useClickOutside([elRef], () => {
    setShow(false);
    setShouldSetStyle(false);
  });

  useLayoutEffect(() => {
    let element = document.getElementById('dropdown-menu-elm');
    if (element) {
      //logic #1
      // const windowHeight = window.innerHeight;
      // const menuHeight = Math.min(200, dropList?.data?.length * 40);
      // const instOffsetWithMenu =
      //   element.getBoundingClientRect().bottom + menuHeight;
      // setShouldSetStyle(instOffsetWithMenu >= windowHeight);

      //logic #2
      if (
        window.innerHeight - element.getBoundingClientRect().bottom >=
        element.scrollHeight
      ) {
        setShouldSetStyle(false);
      } else {
        setShouldSetStyle(true);
      }
    }
  }, [show]);

  return (
    <dropdown-element
      class="relative w-full flex items-center"
      ref={elRef}
      onClick={(e) => {
        e.preventDefault();
        if (closeOnClickOutside) {
          setShow((prev) => !prev);
          setShouldSetStyle(false);
        } else {
          setShow(true);
          setShouldSetStyle(false);
        }
      }}
    >
      {children}

      {showcaret && (
        <dropdown-caret class="absolute right-2">
          <CaretComponent />
        </dropdown-caret>
      )}

      {show && (
        <dropdown-menu
          id="dropdown-menu-elm"
          class={`bg-white absolute border border-grey-400   overflow-auto  rounded-md h-100 shadow-md z-50 ${maxHeight ? "max-h-[165px]" : ""}  ${menuWidth} right-0 custom-drop  ${
            !shouldSetStyle ? 'top-full' : 'bottom-full'
          }`}
        >
          {Header ? (
            <dropdown-header>
              <Header />
            </dropdown-header>
          ) : null}
          <dropdown-menu-body>
            {isLoading ? (
              <span className="flex items-center justify-center h-12">
                <Spinner />
              </span>
            ) : dropList?.data?.length > 0 ? (
              dropList.data
                .sort((a, b) => a?.id - b?.id)
                .map((item, listIndex, listArr) => {
                  return (
                    <dropdown-menu-item
                      dropdown-menu-item
                      key={item.uuid || item.id}
                      class="flex flex-col border-grey-400 border-b text-black cursor-pointer hover:bg-grey-100 last:border-b-0 last:rounded-b-md first:rounded-t-md "
                    >
                      <dropList.component
                        item={item}
                        listIndex={listIndex}
                        listArr={listArr}
                      />
                    </dropdown-menu-item>
                  );
                })
            ) : (
              <>
                <span className="flex items-center justify-center">
                  No record
                </span>
              </>
            )}
          </dropdown-menu-body>
          {Footer ? (
            <dropdown-footer>
              <Footer />
            </dropdown-footer>
          ) : null}
        </dropdown-menu>
      )}
    </dropdown-element>
  );
};

export default Dropdown;
