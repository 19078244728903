// import React, { useMemo, useState } from 'react';
// import { AsideModal, Button, Dropdown, TableManager } from 'components';
// import { getPlanListing } from 'api';
// import { dropdownActions, initialModalState } from './constants';
// import EditPlan from './EditPlan';
// import { Edit, MenuIcon } from 'assets/images';

// function Plan() {
//   const [filters, setFilters] = useState([]);
//   const [openFilter, setOpenFilter] = useState(false);
//   const [showModal, setShowModal] = useState(initialModalState);

//   const handleDropListClick = ({ value = '' }, element = {}) => {
//     switch (value) {
//       case dropdownActions.EDIT_PLAN:
//         setShowModal({ isShow: true, modalType: value, elementData: element });
//         break;

//       default:
//         break;
//     }
//   };

//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: 'plan_name',
//         header: () => 'Name',

//         meta: {
//           textAlign: 'left',
//         },
//       },
//       {
//         accessorKey: 'features',
//         header: () => 'Cost',
//         cell: (props) => {
//           const element = props?.row?.original;
//           const costElement = element?.features.find(
//             (item) => item.key === 'cost',
//           );
//           return costElement ? costElement.value : '';
//         },
//       },

//       {
//         accessorKey: 'features',
//         header: () => 'Phone Numbers Per User',
//         cell: (props) => {
//           const element = props?.row?.original;
//           const costElement = element?.features.find(
//             (item) => item.key === 'phone_numbers_per_user',
//           );
//           return costElement
//             ? costElement.value === '32767'
//               ? 'Unlimited'
//               : costElement.value
//             : '';
//         },
//         meta: {
//           textAlign: 'left',
//           width: '20%',
//         },
//       },
//       {
//         accessorKey: 'features',
//         header: () => 'Additional Phone Number',
//         cell: (props) => {
//           const element = props?.row?.original;
//           const costElement = element?.features.find(
//             (item) => item.key === 'additional_phone_number',
//           );
//           return costElement ? costElement.value : '';
//         },
//         meta: {
//           textAlign: 'left',
//           width: '20%',
//         },
//       },
//       {
//         accessorKey: 'features',
//         header: () => 'Free Call Valid For One Month',
//         cell: (props) => {
//           const element = props?.row?.original;
//           const costElement = element?.features.find(
//             (item) => item.key === 'free_call_valid_for_one_month',
//           );
//           return costElement ? costElement.value : '';
//         },
//         meta: {
//           textAlign: 'left',
//           width: '20%',
//         },
//       },
//       {
//         accessorKey: 'features',
//         header: () => 'OffNet Call Rate Discount',
//         cell: (props) => {
//           const element = props?.row?.original;
//           const costElement = element?.features.find(
//             (item) => item.key === 'offnet_call_rate_discount',
//           );
//           return costElement ? costElement.value : '';
//         },
//         meta: {
//           textAlign: 'left',
//           width: '15%',
//         },
//       },
//       {
//         accessorKey: 'action',
//         header: () => 'Action',
//         cell: (props) => {
//           const element = props?.row?.original || {};
//           return (
//             <div className="flex gap-2 items-center">
//               <Dropdown
//                 menuWidth="w-max"
//                 maxHeight={false}
//                 closeOnClickOutside={true}
//                 dropList={{
//                   component: ({ item }) => {
//                     const Icon = item?.icon ?? null;
//                     const del = item?.type === 'del' ? 'text-danger' : '';
//                     if (item.access) {
//                       return (
//                         <span
//                           className={`w-full h-full flex justify-start gap-2 items-center py-2 px-3 ${del}`}
//                           onClick={() => handleDropListClick(item, element)}
//                         >
//                           {Icon} {item?.title}
//                         </span>
//                       );
//                     }
//                   },
//                   data: [
//                     {
//                       id: 1,
//                       title: 'Edit Plan',
//                       value: dropdownActions.EDIT_PLAN,
//                       icon: <Edit className="text-grey-700" />,
//                       access: true,
//                     },
//                   ],
//                 }}
//                 showcaret={false}
//               >
//                 <div className="cursor-pointer w-1">
//                   <MenuIcon />
//                 </div>
//               </Dropdown>
//             </div>
//           );
//         },

//         meta: {
//           colType: 'action',
//           width: '5%',
//           hasAccess: true,
//         },
//       },
//     ],
//     [],
//   );

//   const tableActions = [
//     {
//       id: 1,
//       label: 'Get a Fonu number +',

//       component: (
//         <div className=" w-64">
//           <Button type="button" height="h-10">
//             Get a Fonu number +
//           </Button>
//         </div>
//       ),
//     },
//   ];

//   const filterInitialValues = {
//     filter: [
//       {
//         plan_name: '',
//         a_filter_type: '',
//         placeholder: 'Enter Plan',
//         type: 'text',
//         shouldFilterOptionDisabled: false,
//         label: 'Plan',
//         filterName: 'plan_name',
//         position: 1,
//       },
//     ],
//   };

//   const ModalLookup = {
//     [dropdownActions.EDIT_PLAN]: (
//       <EditPlan
//         handleClose={() => setShowModal(initialModalState)}
//         showModal={showModal}
//       />
//     ),
//   };
//   const { isShow, modalType } = showModal;

//   function handleCloseModal() {
//     setShowModal(initialModalState);
//   }

//   return (
//     <>
//       <div className="flex items-center justify-between mb-5"></div>

//       <TableManager
//         fetcherFn={getPlanListing}
//         fetcherKey={'getPlanListing'}
//         searchPlaceholder={'Search by  name'}
//         searchLabel=""
//         isSearchable={true}
//         shouldFilter={false}
//         columns={columns}
//         tableActions={tableActions}
//         setOpenFilter={setOpenFilter}
//         openFilter={openFilter}
//         filterInitialValues={filterInitialValues}
//         setFilters={setFilters}
//         filters={filters}
//       />
//       {isShow && (
//         <>
//           <AsideModal
//             handleClose={handleCloseModal}
//             footerComponent={null}
//             headerComponent={null}
//             shouldCloseOnClickOutside={true}
//           >
//             {ModalLookup[modalType]}
//           </AsideModal>
//         </>
//       )}
//     </>
//   );
// }

// export default Plan;

import React from 'react';
import PricingItem from './PricingItem';
import { getPlanListing } from 'api';
import { useQuery } from '@tanstack/react-query';
import { Skeletons } from 'components';

const numberOfPlans = 3;

const optionsItems = [
  {
    key: 'cost',
    value: 'Cost',
    type: 'value',
  },
  {
    key: 'call_local',
    value: 'Call Local',
    type: 'value',
  },
  {
    key: 'messaging_local',
    value: 'Messaging Local',
    type: 'value',
  },
  {
    key: 'review_and_setup_free',
    value: 'Review and Setup Free',
    type: 'value',
  },
  {
    key: 'phone_numbers_per_user',
    value: 'Phone Numbers Per User',
    type: 'value',
  },
  {
    key: 'additional_phone_number',
    value: 'Additional Phone Number',
    type: 'value',
  },
  {
    key: 'free_call_valid_for_one_month',
    value: 'Free Call Valid For One Month',
    type: 'value',
  },
  {
    key: 'offnet_call_rate_discount',
    value: 'OffNet Call Rate Discount',
    type: 'value',
  },
  {
    key: 'call_recording',
    value: 'Call Recording',
    type: 'value',
  },
  {
    key: 'mobile_app_feature_for_customer_service',
    value: 'Mobile App Feature For Customer Service',
    type: 'value',
  },
  {
    key: 'transfer_call_feature',
    value: 'Transfer Call Feature',
    type: 'value',
  },
  {
    key: 'voice_response_ivr',
    value: 'Voice Response IVR',
    type: 'value',
  },
  {
    key: 'business_hour_settings',
    value: 'Business Hour Settings',
    type: 'value',
  },
];

const PlanLoader = () => (
  <div className={`border border-grey-400 rounded overflow-hidden w-[30%]`}>
    <div className={` h-1`}></div>
    <div className={`h-32 p-3  border-b border-grey-400`}>
      <Skeletons />
    </div>
    <div>
      {Array.from({ length: optionsItems?.length - 1 }, (_, i) => i + 1).map(
        (val) => {
          return (
            <div
              key={val}
              className={`border-b border-grey-400 p-3 
            }`}
            >
              <Skeletons />
            </div>
          );
        },
      )}
    </div>
  </div>
);

const Plan = () => {
  const {
    isLoading: isLoadingData,
    data,
    isFetching,
  } = useQuery({
    queryFn: ({ queryKey }) => getPlanListing(queryKey[1] || {}),
    queryKey: [`getPlanListing`],
    select: (data) => data?.data?.data,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    gcTime: Infinity,
  });

  const isLoading = isLoadingData || isFetching;

  return (
    <>
      <div className="px-6 py-6">
        <div className="flex gap-7">
          <PricingItem
            title={'Options'}
            itemsArr={optionsItems}
            width="w-2/5"
            headerBg="bg-green-100"
            withActionBtn={false}
            textCenter={false}
            isLoading={isLoading}
            data={data}
          />

          {isLoading ? (
            <>
              {Array.from({ length: numberOfPlans }, (_, i) => i + 1).map(
                (val) => {
                  return <PlanLoader key={val} />;
                },
              )}
            </>
          ) : data && data.length > 0 ? (
            <>
              {data?.map((plan) => {
                return (
                  <PricingItem
                    plan={plan}
                    title={plan?.plan_name}
                    key={plan?.uuid}
                    itemsArr={plan?.features}
                    isLoading={isLoading}
                    data={data}
                    highiLight={'bg-black-600'}
                    bg={'bg-white'}
                    headerBg={'bg-white'}
                    planId={plan?.uuid}
                    action={plan?.action}
                  />
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Plan;
