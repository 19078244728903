import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Chevron,
  Edit,
  Prohibit,
  SignOut,
  UserCircle,
} from '../../assets/images';
import { useAuth } from 'hooks/useAuth';
import Dropdown from 'components/Dropdown';
import { Logo } from 'assets/images';
import { NG_CURRENCY_SYMBOL, navigationArr } from 'shared/resources';
import { Avatar } from 'components';

function DropdownList({ item }) {
  const Icon = item?.icon;
  return (
    <div
      className="w--full flex  items-center gap-3 py-2 px-3"
      onClick={item?.onclick}
    >
      {Icon} {item?.title}
    </div>
  );
}

export default function Header() {
  const { clearUser, user } = useAuth();
  const [sidebarList, setSidebarList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const newArra = navigationArr?.filter((item) =>
      user?.access_permissions?.includes(item?.id),
    );
    setSidebarList(newArra);
  }, [navigationArr, user?.access_permissions]);

  return (
    <div className="bg-black-600 py-5 flex items-center justify-between border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
      <div className="flex text-base items-center xxl:gap-14 xl:gap-10 lg:gap-8 md:gap-6 sm:gap-8 xs:gap-6 xxl:justify-between xl:justify-start lg:justify-start md:justify-start sm:justify-between xs:justify-between xxl:w-auto xl:w-auto lg:w-auto md:w-auto sm:w-[95%] xs:w-auto">
        <Logo className="w-20" />

        <div className="items-center gap-4 xxl:hidden xl:hidden lg:hidden md:hidden sm:flex xs:flex">
          <div className="text-white">
            <Dropdown
              caretComponent={() => <></>}
              extraClassName=" bg--black-200"
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => (
                  <DropdownList key={item?.id} item={item} />
                ),
                data: [
                  {
                    title: 'Profile',
                    value: 'profile',
                    onclick: () => navigate('/profile/general'),
                    icon: <Prohibit className="text-grey-700" />,
                    id: 1,
                  },
                  {
                    title: 'Plan & billing',
                    value: 'planBilling',
                    onclick: () => navigate('/profile/pnb'),
                    icon: <Prohibit className="text-grey-700" />,
                    id: 2,
                  },
                  {
                    title: 'Blocklist',
                    value: 'blocklist',
                    onclick: () => navigate('/profile/blocklist'),
                    icon: <Edit className="text-grey-700" />,
                    id: 3,
                  },
                  {
                    title: 'Logout',
                    value: 'logout',
                    onclick: () => clearUser(),
                    icon: <Edit className="text-grey-700" />,
                    id: 4,
                  },
                ],
              }}
              showcaret={true}
            ></Dropdown>
          </div>
        </div>

        <div className="flex items-center xxl:gap-8 xl:gap-6 lg:gap-6 md:gap-6 xxl:flex xl:flex lg:flex md:flex sm:hidden xs:hidden pl-20 ">
          {sidebarList?.map((nav) => {
            const isActive = window.location.href.includes(nav?.parent);
            return (
              <div
                key={nav?.id}
                className="flex justify-center items-center group relative"
              >
                <NavLink
                  to={nav?.path}
                  className={
                    isActive
                      ? 'text-white xxl:text-base xl:text-base lg:text-sm md:text-sm font-medium cursor-pointer'
                      : 'text-grey-800 cursor-pointer xxl:text-base xl:text-base lg:text-sm md:text-sm group-hover:text-white'
                  }
                >
                  {nav?.label}
                </NavLink>
                <div
                  className={`${
                    isActive ? 'visible' : 'invisible'
                  } group-hover:visible w-3 h-0.5 bg-green rounded-sm absolute top-7`}
                ></div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="min-w-150 text-white  max-w-150">
        <Dropdown
          caretComponent={Chevron}
          extraClassName="min-w-150  max-w-150  bg--black-200"
          closeOnClickOutside={true}
          dropList={{
            component: ({ item }) => (
              <DropdownList key={item?.id} item={item} />
            ),
            data: [
              {
                title: 'My account',
                value: 'profile',
                onclick: () => navigate('/profile/general'),
                icon: <UserCircle className="text-grey-700 w-5 h-5" />,
                id: 1,
              },

              {
                title: 'Sign out',
                value: 'logout',
                onclick: () => clearUser(),
                icon: <SignOut className="text-grey-700 w-5 h-5" />,
                id: 4,
              },
            ],
          }}
          showcaret={true}
        >
          <div className="flex items-center gap-3 cursor-pointer">
            <Avatar name={`${user?.first_name} ${user?.last_name}`} />

            <div className="flex flex-col gap-1">
              <div className="flex items-center text-sm font-medium	 text-white">
                {NG_CURRENCY_SYMBOL} 0.00
              </div>
            </div>
          </div>
        </Dropdown>
        {/* </div> */}
      </div>
    </div>
  );
}
