export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
};

export const DocumentUploadConstant = {
  NATIONAL_IDENTITY: 'NATIONAL_IDENTITY_NUMBER',
  BANK_VERIFICATION_NUMBER: 'BANK_VERIFICATION_NUMBER',
  DRIVING_LICENSE: 'DRIVING_LICENSE',
  PASSPORT: 'PASSPORT',
  VOTER_CARD: 'VOTER_CARD',
  SELFIE_MODAL: 'selfie_modal',
  IDENTIFICATION_MODAL: 'identification_modal',
};

export const options = [
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Submitted',
    value: 'SUBMITTED',
  },
  {
    label: 'In Review',
    value: 'IN_REVIEW',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
];

export const dropdownActions = {
  ADD_COMPANY: 'add_company',
  View: 'view',
  // UPDATE_COMPANY: 'update_company',
  ACTIVATE_COMPANY: 'activate_company',
  DEACTIVATE_COMPANY: 'deactivate_company',
};