import {
  AsideModal,
  Avatar,
  Button,
  Dropdown,
  Modal,
  TableManager,
} from 'components';
import React, { useState } from 'react';
import { getCompanyList } from 'api';
import {
  colorLookup,
  formatDateTime,
  formatPhoneNumber,
} from 'shared/resources';
import { useAuth } from 'hooks/useAuth';
import { dropdownActions, initialModalState } from './constants';
import { Edit, MenuIcon } from 'assets/images';
import { useActivateCompany, useDeactivateCompany } from 'hooks/index';
import ConfirmationModal from './ConfirmationModal';
import AddCompanyModal from './AddCompanyModal';
import { useNavigate } from 'react-router-dom';

function Companies() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(initialModalState);

  const { mutateAsync: activateCompanMutate, isPending: activateCompanyLoad } =
    useActivateCompany({handleSuccess:handleCloseModal});
  const {
    mutateAsync: deactivateCompanMutate,
    isPending: deactivateCompanyLoad,
  } = useDeactivateCompany({handleSuccess:handleCloseModal});
  const { isShow, modalType, type } = showModal;
  const itemElement = showModal?.elementData ?? {};
  const title = (itemElement?.first_name || '') + ' ' + (itemElement?.last_name || '');

    function handleCloseModal() {
      setShowModal(initialModalState);
    }

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: (props) => {
        const [date] = formatDateTime(props.getValue());
        return date;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'company_name',
      header: () => 'Company Name',
      cell: (props) => {
        const element = props?.row?.original || {};
        return (
          <span className="flex items-center gap-2">
            <Avatar
              name={element?.company_name}
              mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
                user?.company_uuid || user?.uuid
              }/company_logo/${element?.company_logo}`}
            />
            <div className="flex flex-col items-start">
              <span className="font-light text-xs ">
                {element?.company_name}
              </span>
            </div>
          </span>
        );
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'user',
      header: () => 'Full Name',
      cell: (props) =>
        `${props.getValue()?.first_name} ${props.getValue()?.last_name}`,
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'user.email',
      header: () => 'Email',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'user.phone',
      header: () => 'Phone',
      cell: (props) => {
        return formatPhoneNumber(props?.getValue());
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'plan.plan_name',
      header: () => 'Plan Name',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'plan_start_date',
      header: () => 'Plan Start Date',
      cell: (props) => {
        const [date] = formatDateTime(props.getValue());
        return date;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'plan_renew_date',
      header: () => 'Plan Renewal Date',
      cell: (props) => {
        const [date] = formatDateTime(props.getValue());
        return date;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) =>
        props?.row?.original?.status === 1 ? (
          <span>Active</span>
        ) : (
          <span>Deactivate</span>
        ),
    },
    {
      accessorKey: 'kyc_verification',
      header: () => 'KYC Status',
      cell: (props) => {
        return (
          <div
            className={`capitalize ${
              colorLookup[props?.row?.original?.kyc_verification?.toLowerCase()]
            }`}
          >
            {props?.row?.original?.kyc_verification
              ?.toLowerCase()
              ?.split('_')
              ?.join(' ')}
          </div>
        );
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original || {};
        return (
          <div className="flex gap-2 items-center">
            <Dropdown
              menuWidth="w-max"
              maxHeight={false}
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => {
                  const Icon = item?.icon ?? null;
                  const del = item?.type === 'del' ? 'text-danger' : '';

                  if (item.access) {
                    return (
                      <span
                        className={`w-full h-full flex justify-start gap-2 items-center py-2 px-3 ${del}`}
                        onClick={() => handleDropListClick(item, element)}
                      >
                        {Icon} {item?.title}
                      </span>
                    );
                  }
                },
                data: [
                  {
                    id: 1,
                    title: 'Edit Company',
                    value: dropdownActions.ADD_COMPANY,
                    icon: <Edit className="text-grey-700" />,
                    access: true,
                  },
                  {
                    id: 2,
                    title: 'View',
                    value: dropdownActions.View,
                    icon: <Edit className="text-grey-700" />,
                    access: true,
                  },
                  {
                    id: 3,
                    title: element?.status ? 'Deactivate' : 'Activate',
                    value: element?.status
                      ? dropdownActions.DEACTIVATE_COMPANY
                      : dropdownActions.ACTIVATE_COMPANY,
                    icon: <Edit className="text-grey-700" />,
                    access: true,
                  },
                ],
              }}
              showcaret={false}
            >
              <div className="cursor-pointer w-1">
                <MenuIcon />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        textAlign: 'left',
      },
    },
  ];

  const tableActions = [
    {
      id: 1,
      label: 'Add Company',
      access: true,
      component: (
        <div className="w-64">
          <Button
            type="button"
            height="h-10"
            onClick={() =>
              setShowModal({
                isShow: true,
                modalType: dropdownActions.ADD_COMPANY,
              })
            }
          >
            Add Company
          </Button>
        </div>
      ),
    },
  ];

  const ModalLookup = {
    [dropdownActions.ADD_COMPANY]: (
      <AddCompanyModal
        handleClose={() => setShowModal(initialModalState)}
        showModal={showModal}
      />
    ),
    [dropdownActions.ACTIVATE_COMPANY]: (
      <ConfirmationModal
        title={title}
        showModal={showModal}
        handleClose={handleCloseModal}
        activateDeactivateFn={activateCompanMutate}
        isLoading={activateCompanyLoad}
      />
    ),
    [dropdownActions.DEACTIVATE_COMPANY]: (
      <ConfirmationModal
        title={title}
        showModal={showModal}
        handleClose={handleCloseModal}
        activateDeactivateFn={deactivateCompanMutate}
        isLoading={deactivateCompanyLoad}
      />
    ),
  };

  const handleDropListClick = ({ value = '' }, element = {}) => {
    switch (value) {
      case dropdownActions.ADD_COMPANY:
        setShowModal({ isShow: true, modalType: value, elementData: element });
        break;
      case dropdownActions.View:
        navigate(
          `/company-info/${element?.uuid}/kyc/${element?.kyc_verification_details?.uuid}`,
          {
            state: element,
          },
        );
        break;
      case dropdownActions.ACTIVATE_COMPANY:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'center',
        });
        break;
      case dropdownActions.DEACTIVATE_COMPANY:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'center',
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mb-5"></div>

      <TableManager
        fetcherFn={getCompanyList}
        fetcherKey={'getCompanyList'}
        // searchPlaceholder={'Search'}
        // searchLabel=""
        // isSearchable={true}
        // shouldFilter={true}
        columns={columns}
        tableActions={tableActions}
      />
      {isShow && (
        <>
          {type === 'center' ? (
            <Modal
              handleClose={handleCloseModal}
              headerComponent={null}
              footerComponent={null}
              shouldCloseOnClickOutside={false}
            >
              {ModalLookup[modalType]}
            </Modal>
          ) : (
            <AsideModal
              handleClose={handleCloseModal}
              footerComponent={null}
              headerComponent={null}
              shouldCloseOnClickOutside={true}
            >
              {ModalLookup[modalType]}
            </AsideModal>
          )}
        </>
      )}
    </>
  );
}

export default Companies;
