import { routes } from './routes';
import { apiClient } from './axios';

export const login = (value) => {
  return apiClient({
    method: routes.LOGIN.METHOD,
    url: routes.LOGIN.URL,
    data: value,
  });
};
export const updateSubadmin = (value) => {
  return apiClient({
    method: routes.UPDATE_SUB_ADMIN.METHOD,
    url: routes.UPDATE_SUB_ADMIN.URL,
    data: value,
  });
};

export const updatePlan = (value) => {
  return apiClient({
    method: routes.UPDATE_PLAN.METHOD,
    url: routes.UPDATE_PLAN.URL,
    data: value,
  });
};

export const updateIVRMutate = (value) => {
  return apiClient({
    method: routes.UPDATE_IVR_NUMBER.METHOD,
    url: routes.UPDATE_IVR_NUMBER.URL,
    data: value,
  });
};

export const getAdminDetails = (value) => {
  return apiClient({
    method: routes.GET_ADMIN_DETAILS.METHOD,
    url: routes.GET_ADMIN_DETAILS.URL,
    data: value,
  });
};
export const deleteSubAdmin = (value) => {
  return apiClient({
    method: routes.DELETE_SUB_ADMIN.METHOD,
    url: routes.DELETE_SUB_ADMIN.URL,
    data: value,
  });
};
export const deleteVirtualNumber = (value) => {
  return apiClient({
    method: routes.DELETE_VIRTUAL_NUMBER.METHOD,
    url: routes.DELETE_VIRTUAL_NUMBER.URL,
    data: value,
  });
};
export const getSubAdminList = (value) => {
  return apiClient({
    method: routes.SUB_ADMIN_LIST.METHOD,
    url: routes.SUB_ADMIN_LIST.URL,
    data: value,
  });
};
export const addSubAdmin = (value) => {
  return apiClient({
    method: routes.ADD_SUB_ADMIN.METHOD,
    url: routes.ADD_SUB_ADMIN.URL,
    data: value,
  });
};
export const activateSubAdmin = (value) => {
  return apiClient({
    method: routes.ACTIVATE_SUB_ADMIN.METHOD,
    url: routes.ACTIVATE_SUB_ADMIN.URL,
    data: value,
  });
};
export const deactivateSubAdmin = (value) => {
  return apiClient({
    method: routes.DEACTIVATE_SUB_ADMIN.METHOD,
    url: routes.DEACTIVATE_SUB_ADMIN.URL,
    data: value,
  });
};
export const getCompanyList = (value) => {
  return apiClient({
    method: routes.COMPANY_LIST.METHOD,
    url: routes.COMPANY_LIST.URL,
    data: value,
  });
};
export const addCompany = (value) => {
  return apiClient({
    method: routes.ADD_COMPANY.METHOD,
    url: routes.ADD_COMPANY.URL,
    data: value,
  });
};

export const addVirtualNumber = (value) => {
  return apiClient({
    method: routes.VIRTUAL_NUMBER.METHOD,
    url: routes.VIRTUAL_NUMBER.URL,
    data: value,
  });
};
export const updateCompany = (value) => {
  return apiClient({
    method: routes.UPDATE_COMPANY.METHOD,
    url: routes.UPDATE_COMPANY.URL,
    data: value,
  });
};
export const activateCompany = (value) => {
  return apiClient({
    method: routes.ACTIVATE_COMPANY.METHOD,
    url: routes.ACTIVATE_COMPANY.URL,
    data: value,
  });
};
export const deactivateCompany = (value) => {
  return apiClient({
    method: routes.DEACTIVATE_COMPANY.METHOD,
    url: routes.DEACTIVATE_COMPANY.URL,
    data: value,
  });
};

export const getOwnerListing = (value) => {
  return apiClient({
    method: routes.TENANT_LISTING.METHOD,
    url: routes.TENANT_LISTING.URL,
    data: value,
  });
};

export const getVirtualNumbers = (value) => {
  return apiClient({
    method: routes.VIRTUAL_NUMBERS.METHOD,
    url: routes.VIRTUAL_NUMBERS.URL,
    data: value,
  });
};

export const getPortRequests = (value) => {
  return apiClient({
    method: routes.PORT_REQUESTS_LISTING.METHOD,
    url: routes.PORT_REQUESTS_LISTING.URL,
    data: value,
  });
};

export const getTransactionListing = (value) => {
  return apiClient({
    method: routes.TRANSACTION_LISTING.METHOD,
    url: routes.TRANSACTION_LISTING.URL,
    data: value,
  });
};

export const getCallsListing = (value) => {
  return apiClient({
    method: routes.CALLS_LISTING.METHOD,
    url: routes.CALLS_LISTING.URL,
    data: value,
  });
};

export const getSMSListing = (value) => {
  return apiClient({
    method: routes.GET_SMS_LISTING.METHOD,
    url: routes.GET_SMS_LISTING.URL,
    data: value,
  });
};

export const getPlanListing = (value) => {
  return apiClient({
    method: routes.PLAN_LISTING.METHOD,
    url: routes.PLAN_LISTING.URL,
    data: value,
  });
};

export const getProviderListing = (value) => {
  return apiClient({
    method: routes.GET_PROVIDER_LISTING.METHOD,
    url: routes.GET_PROVIDER_LISTING.URL,
    data: value,
  });
};

export const getRateCardListing = (value) => {
  return apiClient({
    method: routes.RATE_CARD_LISTING.METHOD,
    url: routes.RATE_CARD_LISTING.URL,
    data: value,
  });
};
export const getSupportListing = (value) => {
  return apiClient({
    method: routes.SUPPORT_LISTING.METHOD,
    url: routes.SUPPORT_LISTING.URL,
    data: value,
  });
};
export const getBundleList = (value) => {
  return apiClient({
    method: routes.BUNDLE_LISTING.METHOD,
    url: routes.BUNDLE_LISTING.URL,
    data: value,
  });
};
export const addBundle = (value) => {
  return apiClient({
    method: routes.ADD_BUNDLE.METHOD,
    url: routes.ADD_BUNDLE.URL,
    data: value,
  });
};
export const bundleActivateDeactivate = (value) => {
  return apiClient({
    method: routes.BUNDLE_ACTIVATE_DEACTIVATE.METHOD,
    url: routes.BUNDLE_ACTIVATE_DEACTIVATE.URL,
    data: value,
  });
};
export const getKycList = (value) => {
  return apiClient({
    method: routes.KYC_LISTING.METHOD,
    url: routes.KYC_LISTING.URL,
    data: value,
  });
};
export const updateKYC = (value) => {
  return apiClient({
    method: routes.UPDATE_KYC.METHOD,
    url: routes.UPDATE_KYC.URL,
    data: value,
  });
};
export const kycVerification = (value) => {
  return apiClient({
    method: routes.KYC_VERIFICATION.METHOD,
    url: routes.KYC_VERIFICATION.URL,
    data: value,
  });
};
export const getKycDetails = (value) => {
  return apiClient({
    method: routes.GET_KYC_DETAILS.METHOD,
    url: routes.GET_KYC_DETAILS.URL,
    data: value,
  });
};
export const getKycLogsList = (value) => {
  return apiClient({
    method: routes.REPORT_KYC_LOGS.METHOD,
    url: routes.REPORT_KYC_LOGS.URL,
    data: value,
  });
};
export const getKycPaymentsList = (value) => {
  return apiClient({
    method: routes.REPORT_KYC_PAYMENTS.METHOD,
    url: routes.REPORT_KYC_PAYMENTS.URL,
    data: value,
  });
};
export const getKycUsersList = (value) => {
  return apiClient({
    method: routes.REPORT_KYC_USERS.METHOD,
    url: routes.REPORT_KYC_USERS.URL,
    data: value,
  });
};
export const uploadMedia = (value) => {
  return apiClient({
    method: routes.UPLOAD_MEDIA.METHOD,
    url: `${routes.UPLOAD_MEDIA.URL}`,
    data: value,
  });
};
