// import { PageNotFoundImage } from 'assets/images';
import { Button } from 'components';
import React from 'react';

export default function PageNotFound() {
  function handleReset() {
    return (window.location.href = '/');
  }
  return (
    <div className="grid h-screen px-4 bg-white place-content-center">
      {/* <PageNotFoundImage /> */}
      <div className="text-center">
        <h1 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          404
        </h1>
        <p className="mt-4 mb-2 text-gray-500">We can&apos;t find that page.</p>
        <Button type="button" onClick={() => handleReset()}>
          Go Back
        </Button>
      </div>
    </div>
  );
}
