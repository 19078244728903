import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Toast from 'components/Toast';
import { Auth } from 'hooks/useAuth';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <Auth>
      <QueryClientProvider client={queryClient}>
        <Toast />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Auth>
  );
}

export default App;
