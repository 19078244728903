import React, { forwardRef } from 'react';

const baseInputLabelClasses = 'text-grey-800 text-base font-medium';

const baseSelectClasses =
  'w-full border bg-transparent rounded-md focus:outline-none focus:ring focus:bg-white font-medium text-base text-grey-900';

const SelectInput = (
  {
    options = [],
    required = false,
    placeholder = '',
    label = '',
    name = '',
    error = '',
    extraClasses = '',
    height = 'xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]',
    disabled = false,
    ...rest
  },
  ref,
) => {
  const conditioalClasses = error
    ? 'border-danger focus:border-danger ring ring-danger-100 focus:ring-danger-100 bg-danger-100 focus:bg-danger-100'
    : 'border-grey-400 focus:border-green focus:ring-green-200';
  return (
    <div>
      <div className="flex flex-col gap-2">
        {label ? (
          <label htmlFor={name} className={baseInputLabelClasses}>
            {label} {required ? <span className="text-danger">*</span> : null}
          </label>
        ) : null}
        <select
          {...rest}
          ref={ref}
          placeholder={placeholder}
          className={
            extraClasses +
            ' ' +
            baseSelectClasses +
            ' ' +
            conditioalClasses +
            ' ' +
            height
          }
          id={name}
          disabled={disabled}
        >
          <option value="">{placeholder}</option>
          {options &&
            options?.length > 0 &&
            options.map((item) => {
              return (
                <option value={item?.value} key={item?.value}>
                  {item?.label}
                </option>
              );
            })}
        </select>
      </div>
      {error ? (
        <small className="text-danger text-xs font-normal">{error}</small>
      ) : null}
    </div>
  );
};

export default forwardRef(SelectInput);
