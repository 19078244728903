import { LogoBlack } from 'assets/images';
import React from 'react';

export default function SuspenseLoader() {
  return (
    <div className="h-screen  items-center justify-center bg-green-50 flex flex-col">
      <LogoBlack className="h-44 w-44 animate-pulse" />
    </div>
  );
}
