import React, { useMemo, useState } from 'react';
import { Button, TableManager } from 'components';
import { getRateCardListing } from 'api';

function RateCard() {
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'check',
        header: () => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
            />
          );
        },
        cell: () => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
            />
          );
        },
        meta: {
          colType: 'action',
          width: '5%',
        },
      },
      {
        accessorKey: 'created_at',
        header: () => ' Date',
        cell: () => {
          return;
        },
        meta: {
          isSortable: true,
        },
      },
      {
        accessorKey: 'did_number',
        header: () => 'Name',
        cell: () => {
          return;
        },
      },
      {
        accessorKey: 'forward_call_actions',
        header: () => 'Rate',
        cell: () => {
          return;
        },
      },
      {
        accessorKey: 'forward_call_actions',
        id: 'forward_call_actions_for_forward_to',
        header: () => 'Type',
        cell: () => {
          return <div></div>;
        },
      },
      {
        accessorKey: 'assigned_members',
        header: () => 'Service',
        cell: () => {},
      },
      {
        accessorKey: 'status',
        header: () => 'Action',
        cell: () => {
          return;
        },
      },
    ],
    [],
  );

  const tableActions = [
    {
      id: 1,
      label: 'Get a Fonu number +',

      component: (
        <div className=" w-64">
          <Button type="button" height="h-10">
            Get a Fonu number +
          </Button>
        </div>
      ),
    },
  ];

  const filterInitialValues = {
    filter: [
      {
        name: '',
        a_filter_type: '',
        placeholder: 'Enter name',
        type: 'text',
        shouldFilterOptionDisabled: false,
        label: 'Name',
        filterName: 'name',
        position: 1,
      },
    ],
  };

  return (
    <>
      <div className="flex items-center justify-between mb-5"></div>

      <TableManager
        fetcherFn={getRateCardListing}
        fetcherKey={'getRateCardListing'}
        searchPlaceholder={'Search by number, name, or members'}
        searchLabel=""
        isSearchable={true}
        shouldFilter={true}
        columns={columns}
        tableActions={tableActions}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
      />
    </>
  );
}

export default RateCard;
