import React from 'react';

const LinearLoader = () => {
  return (
    <div className={'h-1 rounded-sm relative overflow-hidden'}>
      <div
        id="animation-div"
        className="h-full w-2/5 absolute animate-linearAnimation bg-grey-900 rounded-sm overflow-x-hidden"
      ></div>
    </div>
  );
};

export default LinearLoader;
