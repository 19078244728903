import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateKYC } from '../../api';
import { toastEmitter } from 'components/Toast';

export default function useUpdateKYC({ handleSuccess = () => null } = {}) {
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: updateKYC,
    mutationKey: 'updateKYC',
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }
      handleSuccess();
      toastEmitter('success', responseData?.data?.message);
      queryClient.invalidateQueries(['getKycDetails']);
    },
  });
  return { ...Request };
}
