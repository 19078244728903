import React from 'react';

const SwitchInput = ({ label = '', name = '', ...rest }, ref) => {
  return (
    <label htmlFor={name} className="relative  cursor-pointer inline-flex	">
      <input
        {...rest}
        id={name}
        ref={ref}
        type="checkbox"
        className="sr-only peer"
        checked={rest?.value}
      />
      <div className="w-11 h-6 bg-grey-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-green-200 dark:peer-focus:ring-green-200 rounded-full peer dark:bg-grey-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:shadow-md after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green "></div>
      {label ? (
        <span className="ms-3 text-sm font-medium text-grey-900 dark:text-grey-300">
          {label}
        </span>
      ) : null}
    </label>
  );
};

export default React.forwardRef(SwitchInput);
