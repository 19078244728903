export { ReactComponent as LogoFullWhite } from './LogoFullWhite.svg';

export { ReactComponent as Vectorup } from './signin/Vectorup.svg';
export { ReactComponent as Vectordown } from './signin/Vectordown.svg';
export { ReactComponent as Facebook } from './signin/Facebook.svg';
export { ReactComponent as Google } from './signin/Google.svg';
export { ReactComponent as Apple } from './signin/apple.svg';
export { ReactComponent as LeftChev } from './signin/LeftChev.svg';
export { ReactComponent as Logo } from './signin/logo.svg';
export { ReactComponent as LogoBlack } from './LogoBlack.svg';
export { ReactComponent as MenuIcon } from './MenuIcon.svg';

export { ReactComponent as Arrow } from './Arrow.svg';
export { ReactComponent as Chevron } from './Chevron.svg';
export { ReactComponent as DotsNine } from './DotsNine.svg';
export { ReactComponent as Password } from './Password.svg';
export { ReactComponent as Edit } from './Edit.svg';
export { ReactComponent as NotificationBell } from './NotificationBell.svg';
export { ReactComponent as Prohibit } from './Prohibit.svg';
export { ReactComponent as SignOut } from './SignOut.svg';
export { ReactComponent as X } from './X.svg';
export { ReactComponent as Refresh } from './Refresh.svg';
export { ReactComponent as Instagram } from './Instagram.svg';
export { ReactComponent as Twitter } from './TwitterIcon.svg';
export { ReactComponent as Whatsapp } from './Whatsapp.svg';
export { ReactComponent as X_Twitter } from './X_Twitter.svg';
export { ReactComponent as PhoneOutgoing } from './PhoneOutgoing.svg';
export { ReactComponent as ArrowsLeftRight } from './ArrowsLeftRight.svg';
export { ReactComponent as NoRecord } from './NoRecord.svg';
export { ReactComponent as RemoveForwarding } from './RemoveForwarding.svg';
export { ReactComponent as Setting } from './Setting.svg';
export { ReactComponent as Trash } from './Trash.svg';
export { ReactComponent as User } from './User.svg';
export { ReactComponent as RemoveUser } from './RemoveUser.svg';
export { ReactComponent as Check } from './Check.svg';
export { ReactComponent as UserCircle } from './UserCircle.svg';
export { ReactComponent as PreviousDoubleLeft } from './PreviousDoubleLeft.svg';
export { ReactComponent as PreviousDoubleRight } from './PreviousDoubleRight.svg';
export { ReactComponent as PreviousLeft } from './PreviousLeft.svg';
export { ReactComponent as PreviousRight } from './PreviousRight.svg';
export { ReactComponent as SortingIcon } from './SortingIcon.svg';
export { ReactComponent as Filter } from './Filter.svg';
export { ReactComponent as EyeOpen } from './eyeOpen.svg';
export { ReactComponent as EyeClose } from './eyeClose.svg';
export { ReactComponent as Search } from './Search.svg';
export { ReactComponent as InfoGrey } from './infoGrey.svg';
export { ReactComponent as Download } from './Download.svg';
export { ReactComponent as BackIcon } from './Back.svg';
