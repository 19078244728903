import { RemoveUser, X } from 'assets/images';
import { Button, Spinner } from 'components';
import React from 'react';

const ConfirmationModal = ({
  handleClose = () => null,
  title = '',
  activateDeactivateFn = () => null,
  isLoading = false,
  showModal = {},
  statusParams = false,
}) => {
  const itemElement = showModal?.elementData ?? {};
  console.log('🚀 ~ itemElement:', itemElement);

  return (
    <>
      <div className="flex justify-end items-center p-4">
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="text-center px-16 pb-5 justify-center">
        <div className="flex justify-center mt-8">
          <RemoveUser className="text-black" />
        </div>
        <div className="text-4xl text-black font-semibold mt-4 mb-1">
          {title ? title : ''}
        </div>
        <div className="text-grey-700 text-base	mt-4 w-96">
          Are you sure you want to
          {` ${itemElement?.status === 1 ? 'deactivate' : 'activate'}`}&nbsp;
          <span className="font-medium"> {title ? title : ''}</span>?
        </div>
        <div className="mt-10 pb-14">
          <Button
            disabled={isLoading}
            background={`${itemElement?.status ? 'danger' : 'green'}`}
            type="button"
            onClick={() =>
              activateDeactivateFn({
                uuid: itemElement?.uuid,
                status: statusParams
                  ? itemElement?.status
                    ? 0
                    : 1
                  : undefined,
                // handleClose: handleClose(),
              })
            }
          >
            <div className="flex w-full px-5 justify-center items-center">
              {isLoading ? (
                <Spinner />
              ) : (
                ` ${itemElement?.status === 1 ? 'Deactivate' : 'Activate'}`
              )}
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
